import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/mainpage.css';
import FetchData from '../../providers/dataProvider';
import NoImage from '../../assets/images/no-image.svg';
import { httpHeaders } from '../../providers/dataProvider';
import urls from '../../constants';
import { useQuery } from 'react-query';


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

function SectorCarousel() {

    const requestOptions = httpHeaders();

    const { isLoading, error, data: ProjectReferences } = useQuery(
        {
            queryKey: ['ProjectReferences'],
            queryFn: () =>
                fetch(urls.PRODUCT_REF_URL, requestOptions).then((res) => res.json()),
            enabled: true,
        }
    );

   

    return (
        <>

            {ProjectReferences && (
                <Carousel responsive={responsive} arrows={window.innerWidth <= 600 ? false : true}>

                    {ProjectReferences?.slice(1).map((item) => (
                        <div className='carousel__item'>
                            {!item?.wsDisplayImg || item?.wsDisplayImg === "undefined" ?
                                <>
                                    <img width="100%" src={NoImage} />
                                    <p>No Image Available</p>
                                </>
                                :
                                <img width="100%" src={item?.wsDisplayImg} />
                            }




                            <div className="overlay"></div>
                            <div className='carousel__caption'>
                                <p>{item?.wsRefName}</p>
                            </div>
                        </div>



                    ))}


                </Carousel>
            )}
        </>
    );
}

export default SectorCarousel


