export const ArrowAnimation = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="261" height="262" viewBox="0 0 261 262">
  <defs>
    <clipPath id="clipPath">
      <rect id="Rectangle_37445" data-name="Rectangle 37445" width="261" height="262" fill="#fff" stroke="#707070" strokeWidth="1"/>
    </clipPath>
    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
      <stop offset="0" stopColor="#00a6ef"/>
      <stop offset="1" stopColor="#142847"/>
    </linearGradient>
  </defs>
  <g id="Mask_Group_61973" data-name="Mask Group 61973" clipPath="url(#clipPath)">
    <g id="Group_67160" data-name="Group 67160" transform="translate(-19.403 -30)">
      <ellipse id="Ellipse_60192" data-name="Ellipse 60192" cx="80.654" cy="80.963" rx="80.654" ry="80.963" transform="translate(82.36 62.055)" opacity="0.247" fill="url(#linear-gradient)"/>
      <g id="Group_66481" className="draw-arrow" data-name="Group 66481" transform="translate(0 0)">
        <g id="Group_66477" data-name="Group 66477" transform="translate(0 231.209) rotate(-48)" opacity="0.517">
          <path id="Path_108014" data-name="Path 108014" d="M240.436,29.805c-21.27,22.618-57.872,21.6-86,12.87C128.42,34.6,103.424,23.7,78.164,13.6,53.855,3.88,26.567-3.638,0,1.782" transform="translate(49.363 17.776)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
          <path id="Path_108015" data-name="Path 108015" d="M18.26,5.608A91.991,91.991,0,0,0,0,0" transform="translate(125.298 -0.238)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
          <path id="Path_108016" data-name="Path 108016" d="M109.872,10.972C95.47,26.286,70.687,25.6,51.644,19.687,34.028,14.216,17.1,6.839,0,0" transform="translate(158.582 11.068)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
          <path id="Path_108017" data-name="Path 108017" d="M-.035,18.613C17.074,6.943,36.533-.069,58.768-.112A143.033,143.033,0,0,1,81.447,1.922C121.079,8.7,157.285,31.44,195.876,43.939s84.53,12.59,113.717-19.769" transform="translate(-0.095 50.198)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
          <path id="Path_108018" data-name="Path 108018" d="M0,0C4.779,2.064,9.568,4.089,14.38,6.022" transform="translate(161.654 119.037)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
          <path id="Path_108019" data-name="Path 108019" d="M-.014,7.185c40.89-18.716,78.7,2.884,115.442,18.964" transform="translate(31.338 87.516)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
          <path id="Path_108020" data-name="Path 108020" d="M0,.184,18.047-.034,18.2,18" transform="translate(250.224 21.509)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
          <path id="Path_108021" data-name="Path 108021" d="M0,.184,18.047-.034,18.2,18" transform="translate(272.185 46.727)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
          <path id="Path_108022" data-name="Path 108022" d="M0,.184,18.047-.034,18.2,18" transform="translate(292.219 73.47)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
          <path id="Path_108023" data-name="Path 108023" d="M0,.185,18.013.016l.2,18.074" transform="matrix(0.469, 0.883, -0.883, 0.469, 168.298, 109.096)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
          <path id="Path_108070" data-name="Path 108070" d="M0,0" transform="translate(158.583 11.068)" fill="none" stroke="#ffb34d" strokeMiterlimit="10" strokeWidth="2"/>
        </g>
        <path id="Path_108069" data-name="Path 108069" d="M-.014,7.185c40.89-18.716,78.7,2.884,115.442,18.964" transform="translate(86.006 266.481) rotate(-48)" fill="none" stroke="#2befc1" strokeMiterlimit="10" strokeWidth="2"/>
        <path id="Path_108071" data-name="Path 108071" d="M109.872,10.972C95.47,26.286,70.687,25.6,51.644,19.687,34.028,14.216,17.1,6.839,0,0" transform="translate(114.338 120.765) rotate(-48)" fill="none" stroke="#21cef7" strokeMiterlimit="10" strokeWidth="2"/>
        <path id="Path_108072" data-name="Path 108072" d="M0,.184,18.047-.034,18.2,18" transform="translate(183.417 59.649) rotate(-48)" fill="none" stroke="#21cef7" strokeMiterlimit="10" strokeWidth="2"/>
      </g>
    </g>
  </g>
</svg>
  );
};