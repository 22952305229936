import React, { createContext, useState, useEffect, useContext } from 'react';
import urls from "../../constants";

const TokenContext = createContext();
const TOKEN_EXPIRY_DAYS = 30;
const TOKEN_CHECK_INTERVAL = 24 * 60 * 60 * 1000;

export const TokenProvider = ({ children }) => {
    const [token, setToken] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchToken = async () => {
        try {
            setIsLoading(true);
            setError(null);
            const storedToken = localStorage.getItem('token');
            const tokenTimestamp = localStorage.getItem('tokenTimestamp');

            if (storedToken && tokenTimestamp) {
                const isValid = checkTokenValidity(parseInt(tokenTimestamp));
                if (isValid) {
                    setToken(storedToken);
                    setIsLoading(false);
                    return;
                }
            }

            const params = {
                "WsUsrLogin": "admin",
                "WsUsrPass": "KqacYa+a4MBYSSuehzLWHA=="
            };

            const response = await fetch(urls.LOGIN_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(params),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (!data.token) {
                throw new Error('Token not found in response');
            }

            const currentDateTime = new Date().getTime();
            localStorage.setItem('token', data.token);
            localStorage.setItem('tokenTimestamp', currentDateTime.toString());

            setToken(data.token);

        } catch (error) {
            setError(error.message);
            console.error("Error fetching token:", error);

            // Clear invalid token data
            localStorage.removeItem('token');
            localStorage.removeItem('tokenTimestamp');

        } finally {
            setIsLoading(false);
        }
    };

    const checkTokenValidity = (timestamp) => {
        const currentTime = new Date().getTime();
        const timeDifferenceInMs = currentTime - timestamp;
        const expiryTimeInMs = TOKEN_EXPIRY_DAYS * 24 * 60 * 60 * 1000;
        return timeDifferenceInMs < expiryTimeInMs;
    };

    useEffect(() => {
        fetchToken();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const tokenTimestamp = localStorage.getItem('tokenTimestamp');
            if (tokenTimestamp && !checkTokenValidity(parseInt(tokenTimestamp))) {
                fetchToken();
            }
        }, TOKEN_CHECK_INTERVAL);

        return () => clearInterval(intervalId);
    }, []);

    const value = {
        token,
        isLoading,
        error,
        refreshToken: fetchToken
    };

    return (
        <TokenContext.Provider value={value?.token}>
            {children}
        </TokenContext.Provider>
    );
};

export const useToken = () => {
    const context = useContext(TokenContext);
    if (!context) {
        throw new Error('useToken must be used within a TokenProvider');
    }
    return context;
};