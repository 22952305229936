import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Upbutton from '../../assets/images/upbtn.png';
import Next from '../../assets/images/seemore-white.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CareerCarousel from "./CareerCarousel";
import careerlearn from '../../assets/images/career-learning.png';
import CounterPlus from '../../assets/images/counter-plus.svg';
import { Accordion } from 'react-bootstrap';
import Apply from '../../assets/images/download-right-blue.svg';
import filter from '../../assets/images/filter-black.png';
import FetchData,{httpHeaders} from "../../providers/dataProvider";
import urls from "../../constants";
import {
    useQuery
} from 'react-query'
import { CurveArrow } from "../Mainpage/CurveArrowAnimation";

const Careers = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [locationdata, setLocation] = useState([]);
    const [checked, setChecked] = useState([]);
    const [departmentdata, setDepartment] = useState([]);
    const [careersData, setCareersData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchEnter, setSearchEnter] = useState(false);
    const [visibleJob, setVisibleJob] = useState(5);
    const loadMoreCount = 5;

    const requestOptions = httpHeaders();
   // const { data: careersdata, loading, error } = FetchData("careers");

    const { data:careersdata, loading, error } = useQuery({
        queryKey: ['careers'],
        queryFn: () =>
            fetch(`${urls.CAREER_LIST_URL}`, requestOptions).then(
                (res) => res.json(),
            ),
    })

    useEffect(() => {
        let departmentarr = [];
        let locationarr = [];

        careersdata?.map((item, index) => {
            if (!departmentarr.includes(item?.department)) {
                departmentarr.push(item?.department)
            }

            if (!locationarr.includes(item?.jobLocation)) {
                locationarr.push(item?.jobLocation)
            }
        })

        if (searchQuery !== "") {
            departmentarr = departmentarr.filter(function (item) {
                return item.toLowerCase().includes(searchQuery.toLowerCase())
            })
        }

        setDepartment(departmentarr);
        setLocation(locationarr);

    }, [careersdata, searchQuery])


    useEffect(() => {
        let filterdata = [];
        if (checked?.length > 0) {
            let list = checked.map(item => item.value);

            filterdata = careersdata?.filter(function (item) {
                return list.includes(item?.department) ||
                    list.includes(item?.jobLocation)
            })
            setCareersData(filterdata)
        } else
            setCareersData(careersdata)
    }, [careersdata, checked])

    const [showFilter, handleFilter] = useState(false);

    const handleCareersDetails = () => {
        navigate("/CareerDetail")
    }

    const handleCheckItem = (item, type) => {
        const checked_ = [...checked];
        const itemIndex = checked_.findIndex((chkitem) => chkitem.value === item);
        if (itemIndex !== -1) {
            checked_.splice(itemIndex, 1);
        } else {
            let filterdata = []
            if (type === "department") {
                filterdata = careersdata?.filter(function (item1) {
                    return item1?.department == item
                })
            }
            else if (type === "location") {
                filterdata = careersdata?.filter(function (item1) {
                    return item1?.jobLocation == item
                })
            }
            checked_.push({ value: item, length: filterdata?.length });
        }
        setChecked(checked_);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setSearchEnter(true);
        }
    };

    useEffect(() => {
        if (searchQuery === "") setSearchEnter(false);
    }, [searchQuery])

    const ApplyJob = (item) =>{
        navigate('/career-detail', {
            state: {
                jobdetail: item,
            }
        })
    }

    const handleLoadMore = () => {
        setVisibleJob((prevVisibleJob) => prevVisibleJob + loadMoreCount);
    };

    return (
        <>
            <div className="Careers">
                {
                    !showFilter &&
                    <>
                        <div className="our_products-sec careers">
                            <div className="container">
                                <div className="sec-bottom">
                                    <h2 className="sec-title">Careers</h2>
                                </div>
                            </div>
                        </div>
                        <div className="bread-crumbs">
                            <nav>
                                <Link to="/about"
                                    className={location.pathname === "/about" ? "breadcrumb-active" : "breadcrumb-not-active"}
                                >
                                    About Us
                                </Link>
                                <span className="breadcrumb-arrow">/</span>
                                <Link to="/careers"
                                    className={location.pathname.startsWith("/careers") ? "breadcrumb-active" : "breadcrumb-not-active"}
                                >
                                    Career
                                </Link>

                            </nav>
                        </div>
                        <div className="our_products-sec careers-sec">
                            <p className="sec-top-title">CAREER OPPORTUNITIES</p>
                            <h2 className="sec-title">At Jersey we know that the success of a company is determined by its employees</h2>
                            <div className="sec-right">
                                <p className="sec-bottom-title">Jersey Manufacturing Company, is a leading manufacturer of Air Control, Air distribution and Noise Control products since 1976. Product offerings include Air Control & Distribution products, Fire and Smoke management products, VAV Terminal Units, Sound Attenuators, Architectural and Performance Louvers.</p>
                                <div className="all-current-opening">
                                <a href="https://www.jerseygroup.com/careers" target="_blank"> <button type="submit" className="btn submit-btn">
                                        See all current opening <img src={Next} />
                                    </button></a>
                                </div>
                            </div>
                        </div>
                        <div className="our_products-sec careers-sec">
                            <div className="life-at">
                                <h4>Life at Jersey</h4>
                                <CareerCarousel />
                            </div>
                        </div>
                        <div className="our_products-sec learning">
                            <Row>
                                <Col sm={12} md={6}>
                                    <h2 className="sec-title">Learning, Opportunity Growth</h2>
                                    <div className="sec-bottom">
                                        <p className="sec-bottom-title">Those are just a few of the many words to describe a career within the Jersey Group. We are a successful company with our Head Office located in the State of Qatar, focused on creating high quality air control and Distribution products. And with multiple businesses within the Jersey Group, you can create a career path that’s varied and unique to you. All while remaining with the same company. So get to know us.
                                            <br />
                                            <br />
                                            Explore our different Subsidiaries, learn about different job opportunities with the Jersey Group, and Build Your Future with Us.
                                        </p>
                                    </div>

                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="right-sec">
                                        <img src={careerlearn} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="benefits-sec">
                            <div className="our_products-sec benefits">
                                <Row>
                                    <Col md={5} sm={12}>
                                        <p className="sec-top-title">BENEFITS</p>
                                        <h2 className="sec-title">Our purpose is to help create better outcomes for the world</h2>
                                    </Col>
                                    <Col md={7} sm={12}>
                                        <p className="counter-field">
                                            <div className="count-col">
                                                <div className="col-sm ">
                                                    <div className='count-box'>
                                                        <img src={CounterPlus} />
                                                        <p className="fs-2 counter-number">01</p>
                                                        <div className="desc-div">
                                                            <h4 className="text-light counter-label">International, collaborative and ambitious team</h4>
                                                            <p className="text-light counter-desc">We are convinced that diverse teams and short communication paths make better decisions. Hence, you will find a collaborative start-up atmosphere with flat hierarchies and direct communication,
                                                                combined with the highest professional standards of a stock corporation.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className='count-box'>
                                                        <img src={CounterPlus} />
                                                        <p className="fs-2 counter-number">02</p>
                                                        <div className="desc-div">
                                                            <h4 className="text-light counter-label">Supporting health and fitness</h4>
                                                            <p className="text-light counter-desc">Health builds the foundation for happiness and performance. That is why we subsidize Qualitrain memberships that include health, sports and wellness offerings, and provide healthy snacks and drinks in our office.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className='count-box'>
                                                        <img src={CounterPlus} />
                                                        <p className="fs-2 counter-number">03</p>
                                                        <div className="desc-div">
                                                            <h4 className="text-light counter-label">Flexible working hours, hybrid working and modern office</h4>
                                                            <p className="text-light counter-desc">We want to deliver excellence. It is up to you whether you do that from home or from the office and at what specific times.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                            <span className="HomeSubsidiaries_bg_line__OTckB career ratio ratio-1x1">
                                <span className="SvgLineBg_bg_line__Aq2b0 SvgLineBg_bg_line_flip__l2Jky">
                                    <CurveArrow />
                                </span>
                            </span>
                        </div>
                        <div className="our_products-sec career-filter" id="currentopen">
                            <p className="sec-top-title">CAREER</p>
                            <h2 className="sec-title">We’re expanding our team join us</h2>
                            <Row className="large-view">
                                <Col md={3}>
                                    <div className="product-filter">
                                        <div className="filter-sec">
                                            <h4>Filter</h4>
                                            <div className="filter-category">
                                                <Accordion  defaultActiveKey={['0','1']} alwaysOpen>
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>Department</Accordion.Header>

                                                        <Accordion.Body>
                                                            <ul>
                                                                <input className="nosubmit"
                                                                    type="search"
                                                                    placeholder="Search Department"
                                                                    value={searchQuery}
                                                                    onKeyDown={handleKeyDown} onChange={(e) => setSearchQuery(e.target.value)} />
                                                                {departmentdata?.length>0? 
                                                                    departmentdata.map((item, index) => {
                                                                    return (
                                                                        <li>
                                                                            <input type="checkbox" checked={checked.some(
                                                                                (checkedItem) =>
                                                                                    checkedItem.value === item
                                                                            )} onChange={() => handleCheckItem(item, "department")} />
                                                                            <label>{item}</label>
                                                                        </li>
                                                                    )
                                                                })
                                                            :""
                                                            // <p className="form-error">Department not found</p>
                                                            }

                                                            </ul>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="1">
                                                        <Accordion.Header>Location</Accordion.Header>
                                                        <Accordion.Body>
                                                            <ul>
                                                                {locationdata.map((item, index) => {
                                                                    return (
                                                                        <li>
                                                                            <input type="checkbox" checked={checked.some(
                                                                                (checkedItem) =>
                                                                                    checkedItem.value === item
                                                                            )} onChange={() => handleCheckItem(item, "location")} />
                                                                            <label>{item}</label>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={9}>
                                    {careersData?.slice(0, visibleJob)?.length>0?
                                    <div className="right-side-table">
                                        <div className="gray-text">
                                            {checked.map((item, index) => {
                                                return (
                                                    <p>{item?.value} ({item?.length})</p>
                                                )
                                            })}
                                        </div>
                                        <table className="table">
                                            <tbody>
                                                {
                                                    careersData?.slice(0, visibleJob).map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{item?.jobTitle}</td>
                                                                <td>{item?.jobLocation}</td>
                                                                <td>{item?.jobType}</td>
                                                                <td><a onClick={()=>{window.scroll(0, 0);ApplyJob(item)}}>Apply Now <img src={Apply} /> </a></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        <button type="submit" className="btn loadmore-btn" onClick={handleLoadMore}>Load More <img src={Next} /></button>
                                    </div>:<p className="not-available">No current openings available</p>}
                                </Col>
                            </Row>
                            <Row className="m-view" id="career-filter">
                                <Col md={12}>
                                {careersData?.slice(0, visibleJob)?.length>0?
                                   <> <div onClick={() => {window.scroll(0, 0);}}>
                                    <button type="submit" className="filter btn" onClick={handleFilter}>Filter <img src={filter} /></button>
                                    </div>
                                    <div className="gray-text">
                                        {checked.map((item, index) => {
                                            return (
                                                <p>{item?.value} ({item?.length})</p>
                                            )
                                        })}
                                    </div>
                                    <div className="opportunities">
                                        {
                                            careersData?.slice(0, visibleJob).map((item, index) => {
                                                return (
                                                    <div className="jobs">
                                                        <h4>{item?.jobTitle}</h4>
                                                        <div className="job-det">
                                                            <p>{item?.jobLocation}</p>
                                                            <p>{item?.jobType}</p>
                                                        </div>
                                                        <button type="submit" onClick={()=>{window.scroll(0, 0); ApplyJob(item)}} className="submit btn">Apply Now <img src={Apply} /></button>
                                                    </div>
                                                )
                                            })
                                        }

                                        <div className="loadmore-div">
                                            <button type="submit" className="btn loadmore-btn" onClick={handleLoadMore}>Load More <img src={Next} /></button>
                                        </div>
                                    </div></>:<p className="not-available">No current openings available</p>}
                                </Col>
                            </Row>
                        </div>
                    </>
                }

                {/* Mobile responsive filter view */}
                {
                    showFilter &&
                    <>
                        <div className="Filter-field">
                            <a className="back-btn" onClick={e => handleFilter(false)} href="#career-filter">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                </svg> Back
                            </a>
                            <div className="left-side">
                                <div className="product-filter">
                                    <div className="filter-sec">
                                        <h4>Filters</h4>
                                        <div className="filter-category">
                                            <Accordion  defaultActiveKey={['0','1']} alwaysOpen>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Department</Accordion.Header>

                                                    <Accordion.Body>
                                                        <ul>
                                                            <input className="nosubmit" type="search" placeholder="Search Department"
                                                                value={searchQuery}
                                                                onKeyDown={handleKeyDown} onChange={(e) => setSearchQuery(e.target.value)} />
                                                            {departmentdata?.length>0?
                                                            departmentdata?.map((item, index) => {
                                                                return (
                                                                    <li>
                                                                        <input type="checkbox" checked={checked.some(
                                                                            (checkedItem) =>
                                                                                checkedItem.value === item
                                                                        )} onChange={() => handleCheckItem(item, "department")} />
                                                                        <label>{item}</label>
                                                                    </li>
                                                                )
                                                            })
                                                            :""
                                                            // <p className="form-error">Department not found</p>
                                                            }

                                                        </ul>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Location</Accordion.Header>
                                                    <Accordion.Body>
                                                        <ul>
                                                            {locationdata.map((item, index) => {
                                                                return (
                                                                    <li>
                                                                        <input type="checkbox" checked={checked.some(
                                                                            (checkedItem) =>
                                                                                checkedItem.value === item
                                                                        )} onChange={() => handleCheckItem(item, "location")} />
                                                                        <label>{item}</label>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mobile-filter">
                            <div className="fixedFilter">
                                <button className="nosubmit" type="search" onClick={()=>setChecked([])}>Clear All</button>
                                <hr />
                                <button className="filter">Show {careersData?.length} Results</button>
                            </div>
                        </div>
                    </>
                }


            </div>
        </>
    )
};
export default Careers