import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../assets/css/mainpage.css";
import "../../assets/css/header.scss";
import { Navigate, useNavigate } from "react-router-dom";
import jerseyGroupLogoColor from '../../assets/images/jerseyGroupLogoColor.png';
import jerseyManufacturingLogo from '../../assets/images/jerseyManufacturingLogo.png';
import Arrow from '../../assets/images/right-arrow.svg';
import DownArrow from '../../assets/images/chevron-down-solid.svg';
import UpArrow from '../../assets/images/chevron-up-solid.svg';
import JerseyGroupFaviconColor from '../../assets/images/JerseyGroupFaviconColor.webp';
import QatarInsulation from '../../assets/images/QatarInsulation.webp';
import JerseyEngineering from '../../assets/images/JerseyEngineering.webp';
import JerseyIndusrial from '../../assets/images/JerseyIndusrial.webp';
import JerseyIntegrated from '../../assets/images/JerseyIntegrated.webp';
import JerseyWater from '../../assets/images/JerseyWater.webp';
import JerseyManufacturing from '../../assets/images/JerseyManufacturing.webp';
import JerseyAutomation from '../../assets/images/JerseyAutomation.webp';
import FirelinkQatar from '../../assets/images/FirelinkQatar.webp';
import FirelinkKSA from '../../assets/images/FirelinkKSA.webp';
import JerseyServices from '../../assets/images/JerseyServices.webp';
// https://jsfiddle.net/ur7tns7L/
// https://codepen.io/JacobLett/pen/jaaQYG
const Header = (props) => {
  const location = useLocation();
  const [isUp, setIsUp] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const handleClick = () => {
    setIsUp(!isUp); // Toggle the state
    setIsActive(!isActive);
  };

  return (
    <>
      <div className="header-bar">
        <div className="header-outer-wrapper">
          <div className="header-inner-wrapper">
            <div className="logo-wrapper">
              <Link to="https://www.jerseygroup.com/" className="anchor logo-anchor">
                <picture className="jersey-logo">
                  <img
                    srcSet={jerseyGroupLogoColor}
                    alt="jersey"
                  />
                </picture>
              </Link>
              <Link to="/" className="anchor logo-anchor">
                <picture className="manufacturing-logo">
                  <img
                    srcSet={jerseyManufacturingLogo}
                    alt="jersey manufacturing"
                  />
                </picture>
              </Link>
            </div>
            <div id="menuToggle" className="mobileScreen">
              <input className="sidebar" type="checkbox" />
              <span></span>
              <span></span>
              <ul id="menu">
                <li>
                  <Link to="/" className={location.pathname === "/" ? "active" : ""}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" className={location.pathname === "/about" ? "active" : ""}>
                    About us
                  </Link>
                </li>
                <li>
                  <button className={`btn subsidiaries-btn ${isActive ? 'active' : 'text-white'}`} onClick={handleClick} type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    Subsidiaries
                    {isUp ? <img src={DownArrow} /> : <img src={UpArrow} />}
                  </button>
                  <div class="collapse" id="collapseExample">
                    <div className="row">
                      <div className="col-12">
                        <h1 className="pl-1">Overview</h1>
                      </div>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-12">
                            <h2 className="pl-1">Trading</h2>
                            <ul className="">
                              <li>
                                <Link to="https://www.jerseygroup.com/subsidiary/qatar-insulation" className="nav-link justify-content-start"><i className="fas fa-chevron-right mr-2"></i>Qatar Insulation
                                </Link>
                              </li>
                              <li>
                                <Link to="https://www.jerseygroup.com/subsidiary/jersey-engineering" className="nav-link justify-content-start"><i className="fas fa-chevron-right mr-2"></i>Jersey Engineering
                                </Link>
                              </li>
                              <li>
                                <Link to="https://www.jerseygroup.com/subsidiary/jersey-industrial-insulation" className="nav-link justify-content-start"><i className="fas fa-chevron-right mr-2"></i>Jersey Industrial Insulation
                                </Link>
                              </li>
                              <li>
                                <Link to="https://jerseyintegrated.com/" className="nav-link justify-content-start"><i className="fas fa-chevron-right mr-2"></i>Jersey Integrated(KSA)
                                </Link>
                              </li>
                              <li>
                                <Link to="https://www.jerseygroup.com/subsidiary/jersey-water-environment" className="nav-link justify-content-start"><i className="fas fa-chevron-right mr-2"></i>Jersey Water & Environment
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="col-12">
                            <h2 className="pl-1">Manufacturing</h2>
                            <ul className="">
                              <li>
                                <Link to="https://www.jerseymfg.com/" className="nav-link justify-content-start"><i className="fas fa-chevron-right mr-2"></i>Jersey Manufacturing
                                </Link>
                              </li>
                              <li>
                                <Link to="https://www.jerseygroup.com/subsidiary/jersey-industrial" className="nav-link justify-content-start"><i className="fas fa-chevron-right mr-2"></i>Jersey Industrial Automation
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="col-12">
                            <h2 className="pl-1">Contracting</h2>
                            <ul className="">
                              <li>
                                <Link to="https://www.jerseygroup.com/subsidiary/firelink" className="nav-link justify-content-start"><i className="fas fa-chevron-right mr-2"></i>Firelink Qatar
                                </Link>
                              </li>
                              <li>
                                <Link to="https://firelinksaudi.com/" className="nav-link justify-content-start"><i className="fas fa-chevron-right mr-2"></i>Firelink KSA
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="col-12">
                            <h2 className="pl-1">Services</h2>
                            <ul className="">
                              <li>
                                <Link to="https://www.jerseygroup.com/subsidiary/jersey-services" className="nav-link justify-content-start"><i className="fas fa-chevron-right mr-2"></i>Jersey Services
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                {/* <hr className="sideNav" /> */}
                <li>
                  <Link to="/products" className={location.pathname === "/products" ? "active" : ""}>
                    Products
                  </Link>
                </li>
                <li>
                  <Link to="/reference" className={location.pathname === "/reference" ? "active" : ""}>
                    Projects
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us" className={location.pathname === "/contact-us" ? "active" : ""}>
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="navbar-wrapper largeScreen">
            <nav className="nav-bar mega-navbar">
              <div className="logo-wrapper img">
                <Link to="https://www.jerseygroup.com/" className="anchor logo-anchor">
                  <picture className="jersey-logo">
                    <img
                      srcSet={jerseyGroupLogoColor}
                      alt="jersey"
                    />
                  </picture>
                </Link>
                <Link to="/" className="anchor logo-anchor">
                  <picture className="manufacturing-logo">
                    <img
                      srcSet={jerseyManufacturingLogo}
                      alt="jersey manufacturing"
                    />
                  </picture>
                </Link>
              </div>
              <ul className="parent">
                <li>
                  <Link to="/" className={location.pathname === "/" ? "active" : ""} onClick={() => {
                    window.scroll(0, 0);
                  }}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" className={location.pathname === "/about" ? "active" : ""} onClick={() => {
                    window.scroll(0, 0);
                  }}>
                    About us
                  </Link>
                </li>
                <li className="nav-item dropdown" id="loaded">
                  <a className="nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Subsidiaries
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <h1>Overview</h1>
                        </div>
                        <div className="col-md-8">
                          <div className="row">
                            <div className="col-6 trading-column">
                              <h2>Trading</h2>
                              <ul className="nav flex-column">
                                <li className="nav-item" id="show1">
                                  <Link to="https://www.jerseygroup.com/subsidiary/qatar-insulation" className="nav-link"><i className="fas fa-chevron-right mr-2"></i>Qatar Insulation
                                  </Link>
                                </li>
                                <li className="nav-item" id="show2">
                                  <Link to="https://www.jerseygroup.com/subsidiary/jersey-engineering" className="nav-link"><i className="fas fa-chevron-right mr-2"></i>Jersey Engineering
                                  </Link>
                                </li>
                                <li className="nav-item" id="show3">
                                  <Link to="https://www.jerseygroup.com/subsidiary/jersey-industrial-insulation" className="nav-link"><i className="fas fa-chevron-right mr-2"></i>Jersey Industrial Insulation
                                  </Link>
                                </li>
                                <li className="nav-item" id="show4">
                                  <Link to="https://jerseyintegrated.com/" className="nav-link"><i className="fas fa-chevron-right mr-2"></i>Jersey Integrated(KSA)
                                  </Link>
                                </li>
                                <li className="nav-item" id="show5">
                                  <Link to="https://www.jerseygroup.com/subsidiary/jersey-water-environment" className="nav-link"><i className="fas fa-chevron-right mr-2"></i>Jersey Water & Environment
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="col-6 manufacturing-column">
                              <h2>Manufacturing</h2>
                              <ul className="nav flex-column">
                                <li className="nav-item" id="show6">
                                  <Link to="https://www.jerseymfg.com/" className="nav-link"><i className="fas fa-chevron-right mr-2"></i>Jersey Manufacturing
                                  </Link>
                                </li>
                                <li className="nav-item" id="show7">
                                  <Link to="https://www.jerseygroup.com/subsidiary/jersey-industrial" className="nav-link"><i className="fas fa-chevron-right mr-2"></i>Jersey Industrial Automation
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="col-6 contracting-column">
                              <h2>Contracting</h2>
                              <ul className="nav flex-column">
                                <li className="nav-item" id="show8">
                                  <Link to="https://www.jerseygroup.com/subsidiary/firelink" className="nav-link"><i className="fas fa-chevron-right mr-2"></i>Firelink Qatar
                                  </Link>
                                </li>
                                <li className="nav-item" id="show9">
                                  <Link to="https://firelinksaudi.com/" className="nav-link"><i className="fas fa-chevron-right mr-2"></i>Firelink KSA
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="col-6 services-column">
                              <h2>Services</h2>
                              <ul className="nav flex-column">
                                <li className="nav-item" id="show10">
                                  <Link to="https://www.jerseygroup.com/subsidiary/jersey-services" className="nav-link"><i className="fas fa-chevron-right mr-2"></i>Jersey Services
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="nav-hover-images">
                            <Link id="load" to="/">
                              <img src={JerseyGroupFaviconColor} alt="JerseyGroupFaviconColor" />
                            </Link>
                            <Link id="hover1" to="/">
                              <img src={QatarInsulation} alt="QatarInsulation" />
                            </Link>
                            <Link id="hover2" to="/">
                              <img src={JerseyEngineering} alt="JerseyEngineering" />
                            </Link>
                            <Link id="hover3" to="/">
                              <img src={JerseyIndusrial} alt="JerseyIndusrial" />
                            </Link>
                            <Link id="hover4" to="/">
                              <img src={JerseyIntegrated} alt="JerseyIntegrated" />
                            </Link>
                            <Link id="hover5" to="/">
                              <img src={JerseyWater} alt="JerseyWater" />
                            </Link>
                            <Link id="hover6" to="/">
                              <img src={JerseyManufacturing} alt="JerseyManufacturing" />
                            </Link>
                            <Link id="hover7" to="/">
                              <img src={JerseyAutomation} alt="JerseyAutomation" />
                            </Link>
                            <Link id="hover8" to="/">
                              <img src={FirelinkQatar} alt="FirelinkQatar" />
                            </Link>
                            <Link id="hover9" to="/">
                              <img src={FirelinkKSA} alt="FirelinkKSA" />
                            </Link>
                            <Link id="hover10" to="/">
                              <img src={JerseyServices} alt="JerseyServices" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <Link to="/products" className={location.pathname === "/products" ? "active" : ""} onClick={() => {
                    window.scroll(0, 0);
                  }}>Products
                  </Link>
                </li>
                <li>
                  <Link to="/reference" className={location.pathname === "/reference" ? "active" : ""} onClick={() => {
                    window.scroll(0, 0);
                  }}>
                    Projects
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us" className={location.pathname === "/contact-us" ? "active" : ""} onClick={() => {
                    window.scroll(0, 0);
                  }}>
                    Contact
                  </Link>
                </li>
                <li>
                  <Link to="/login">
                    <button className="loginbtn">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                      </svg> Login
                    </button>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div >
    </>
  );
};

export default Header;
