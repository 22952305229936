import React, { useState, useEffect } from "react";
import { Row, Col } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import BreadCrumb from "../Common/BreadCrumb";
import { httpHeaders } from "../../providers/dataProvider";
import ProductFilter from "./ProductFilter";
import ProductFilterList from "./ProductFilterList";
import { Accordion } from 'react-bootstrap';
import NoImage from '../../assets/images/no-image.svg';
import urls from "../../constants";
import { checknull } from '../../utils/Validate'
import { useQuery, useQueryClient } from 'react-query'

const ProductList = () => {
    const location = useLocation();
    const queryClient = useQueryClient();
    const myParam = new URLSearchParams(location.search);
    const [prodList, setProdList] = useState({ Id: "", Name: "" })

    useEffect(() => {
        setProdList({
            Id: myParam.get('Id'),
            Name: myParam.get('Name'),
        })
    }, [myParam.get('Id')])

    const [values, setValues] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [showSearch, handleSetSearch] = useState(false);
    const [showFilter, handleFilter] = useState(false);
    const [productdisp, setProductDisp] = useState([]);
    const [propertiesdisp, setProperties] = useState([]);
    const [relatedSearch, setRelatedSearch] = useState([]);
    const [uniquepropname, setuniquepropname] = useState([]);
    const [uniquepropvalue, setuniquepropvalue] = useState([]);
    const [checkedprop, setCheckedProp] = useState([]);
    const [checkedprod, setCheckedProd] = useState([]);
    const [productModels, setProductModels] = useState([]);
    const [prodDisplayModels, setProdDisplayModels] = useState([]);
    const requestOptions = httpHeaders();

    const { data, loading, error, } = useQuery({
        queryKey: ['filterList', prodList?.Id],
        queryFn: () =>
            fetch(`${urls.PRODUCT_LIST_URL}${prodList?.Id}`, requestOptions).then(
                (res) => res.json(),
            ),
        enabled: Boolean(prodList?.Id),
        staleTime: 300000,
        cacheTime: 3600000,
        initialData: () => { return queryClient.getQueryData(['filterList', prodList?.Id]) }
    })

    const handleCheckprop = (chkitem) => {
        const checked_ = [...checkedprop];
        const itemIndex = checked_.findIndex((item) => item.prop_value === chkitem.prop_value);
        if (itemIndex !== -1) {
            checked_.splice(itemIndex, 1);
        } else {
            checked_.push(chkitem);
        }
        setCheckedProp(checked_);
    };

    const handleCheckprod = (chkitem) => {
        const checked_ = [...checkedprod];
        const itemIndex = checked_.findIndex((item) => item.productName === chkitem.productName);
        if (itemIndex !== -1) {
            checked_.splice(itemIndex, 1);
        } else {
            checked_.push(chkitem);
        }
        setCheckedProd(checked_);
    };

    const handleClear = (filtername, key) => {
        if (filtername === "product") { setCheckedProd([]) }
        if (filtername === "productprop") {
            const filterprop = checkedprop.filter(function (item) {
                return item.prop_name !== key
            })
            setCheckedProp(filterprop)
        }
    };

    const handleClickclear = (e) => {
        setCheckedProd([]);
        setCheckedProp([]);
        setSearchQuery("")
    }

    useEffect(() => { if (data) { setValues(data[0]?.tblWsProductSubGrps || []) } }, [data]);

    useEffect(() => {
        let productarr = [];
        let productdisplarr = [];
        let modelsarr = [];
        let obj = [];
        values?.map((item, index) => {
            if (item?.wspSgpStatus === true) {
                productarr.push({ productName: item?.wspSgpName, id: item?.wspSgpId })
                const findProduct = checkedprod.find((element) => element.id == item.wspSgpId)
                if (checkedprod?.length > 0) {
                    if (checknull(findProduct) !== "")
                        productdisplarr.push(item)
                }
                else
                    productdisplarr.push(item)
            }

        })

        productdisplarr?.map((item, index) => {
            item?.tblWsProducts?.map((property, prop_index) => {
                property?.tblWsProductModels?.map((models, model_index) => {
                    if (models?.wsmMdlStatus === true) {
                        obj = [];
                        models?.tblWspMdlOptions?.map((modelOptions, model_option_index) => {
                            modelOptions?.tblWspMdlOptValues?.map((optionVal, optionVal_index) => {
                                obj = obj.concat(optionVal?.wsmOptValue);
                            })

                        })
                        if (checkedprop?.length > 0) {
                            if (checkedprop.every((item) => obj.includes(item.prop_value))) {
                                modelsarr.push({ modelsdetails: models, prod_name: item?.wspSgpName, prod_id: item?.wspSgpId })
                            }
                        } else
                            modelsarr.push({ modelsdetails: models, prod_name: item?.wspSgpName, prod_id: item?.wspSgpId })
                    }
                })
            })
        })
        setProductDisp(productarr);
        setProductModels(modelsarr);
        setProdDisplayModels(modelsarr);
    }, [values, checkedprod, checkedprop])

    useEffect(() => {
        let searchResult = [];
        if (searchQuery !== "") {
            productModels?.map((item, index) => {
                if (item?.modelsdetails?.wsmMdlName.toLowerCase().trim().includes(searchQuery.toLowerCase().trim()) > 0) {
                    searchResult.push(item)
                }
            })
            setProdDisplayModels(searchResult);
            if (searchResult?.length === 0) setProdDisplayModels([]);
            if (searchResult?.length === 0) {
                productModels?.map((item, index) => {
                    if (item?.modelsdetails?.wsmMdlName.toLowerCase().trim().includes(searchQuery.substring(0, searchQuery?.length - 1).toLowerCase().trim()) > 0) {
                        searchResult.push(item)
                    }
                })
                setRelatedSearch(searchResult)
            }
        }
        else
            setProdDisplayModels(productModels);
    }, [searchQuery, productModels])

    useEffect(() => {
        let propertiesarr = [];
        productModels?.map((details, detailsindex) => {
            details?.modelsdetails?.tblWspMdlOptions?.map((modelOptions, model_option_index) => {
                modelOptions?.tblWspMdlOptValues?.map((optionVal, optionVal_index) => {

                    const findprop = propertiesarr.find((element) => element.prod_name == details?.prod_name
                        && element.prod_id === details?.prod_id
                        && element.prop_name === modelOptions?.wspOptName
                        && element.prop_value === optionVal?.wsmOptValue)


                    if (checknull(findprop) === "") {
                        propertiesarr.push({
                            prod_name: details?.prod_name,
                            prod_id: details?.prod_id,
                            prop_id: modelOptions?.wspOptId,
                            prop_name: modelOptions?.wspOptName,
                            prop_value: optionVal?.wsmOptValue
                        });
                    }
                })
            })
        })
        setProperties(propertiesarr);
    }, [productModels])

    useEffect(() => {
        propertiesdisp.sort((a, b) => a.prop_id - b.prop_id);
        const key = 'prop_value';
        let arrayUniqueByKey = [...new Map(propertiesdisp.map(item =>
            [item[key], item])).values()];

        const key1 = 'prop_name';

        let arrayUniqueName = [...new Map(propertiesdisp.map(item =>
            [item[key1], item])).values()];

        setuniquepropvalue(arrayUniqueByKey)
        setuniquepropname(arrayUniqueName)

    }, [propertiesdisp])

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setSearchQuery(event.target.value);
        }
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <>
            <div className="products-list">
                {
                    !showSearch && !showFilter &&
                    <>
                        {/* BreadCrumb */}
                        <BreadCrumb prodName="products" moduleName="Category List" prodListName={prodList?.Name} />
                        <div className="our_products-sec products">
                            <Row>
                                <Col md={3} className="left-side">
                                    <div className="product-filter">
                                        <h4>Category List</h4>
                                        <div className="filter-sec">
                                            {(checkedprod?.length > 0 || checkedprop?.length > 0 || searchQuery != "") ?
                                                <a className="clear-btn" onClick={handleClickclear}>Clear All</a> : ""}
                                            <form className="nosubmit">
                                                <input
                                                    className="nosubmit"
                                                    type="search"
                                                    placeholder="Search by model name"
                                                    value={searchQuery}
                                                    onChange={handleSearch}
                                                    onKeyDown={handleKeyDown}
                                                />
                                            </form>
                                            {/* Left Side ProductFilter component */}
                                            <ProductFilter
                                                uniquepropname={uniquepropname}
                                                uniquepropvalue={uniquepropvalue}
                                                productdisp={productdisp}
                                                checkedprod={checkedprod}
                                                checkedprop={checkedprop}
                                                handleClear={handleClear}
                                                handleCheckprop={handleCheckprop}
                                                handleCheckprod={handleCheckprod}
                                                productId={prodList?.Id}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={9}>
                                    {
                                        prodDisplayModels?.length > 0 ?
                                            (<div className="pdt-list-show">
                                                <p className="category-name">{prodList?.Name}</p>
                                                <h4>{prodList?.Name} ({prodDisplayModels?.length} Products)</h4>
                                                <ProductFilterList
                                                    filterData={prodDisplayModels}
                                                    Id={prodList?.Id} Name={prodList?.Name}
                                                />
                                            </div>)
                                            :
                                            (
                                                <>
                                                    {/* No product list found */}
                                                    {searchQuery && (
                                                        <div className="no-pdt-found">
                                                            <div className="no-pdt-title">
                                                                <h6>We're Sorry</h6>
                                                                <p>We Couldn't find a match for "Life Safety Dampers". Please try another search</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <hr />
                                                    {relatedSearch?.length > 0 && (
                                                        <div className="pdt-list-show">
                                                            <h6 className="related-serch">Related Search</h6>
                                                            <Col md={12}>
                                                                <Row>
                                                                    {relatedSearch?.map((prod, index) => (
                                                                        <Col md={4} sm={3} xs={6} lg={4} className="pl-1 pr-1">
                                                                            <Link to="/product-detail" state={{ ProdId: prod?.modelsdetails?.wsmMdlId, GroupId: prodList?.Id, ProdName: prodList?.Name, SubprodName: prod?.modelsdetails?.wsmMdlName }} onClick={() => { window.scroll(0, 0); }}>
                                                                                <div className="carousel__item right">
                                                                                    {prod?.modelsdetails?.tblWsProdMdlImages[0]?.wsmImgUrl == undefined ?
                                                                                        <div className="no-image">
                                                                                            <img width="100%" src={NoImage} />
                                                                                            <p>No Image Available</p>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="image-box">
                                                                                            <img width="100%" src={prod?.modelsdetails?.tblWsProdMdlImages[0]?.wsmImgUrl} />
                                                                                        </div>
                                                                                    }
                                                                                    <div className="carousel__caption">
                                                                                        <h5>{prod?.modelsdetails?.wsmMdlName}</h5>
                                                                                        <p title={prod?.modelsdetails?.wsmMdlSrtDesc}>{prod?.modelsdetails?.wsmMdlSrtDesc}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        </Col>
                                                                    ))}
                                                                </Row>
                                                            </Col>
                                                        </div>
                                                    )}
                                                </>
                                            )
                                    }
                                </Col>
                            </Row>
                        </div>
                    </>
                }
                <div className="mobile-filter">
                    <div className="fixedDiv" onClick={() => { window.scroll(0, 0); }}>
                        <button
                            className="nosubmit"
                            type="submit"
                            placeholder="Search products by name"
                            // value={searchQuery}
                            // onChange={handleSearch}
                            // onKeyDown={handleKeyDown}
                            // onClick={handleSearch}
                            onClick={e => handleSetSearch(true)}
                        >Search</button>
                        <hr />
                        <button className="filter" onClick={handleFilter}>Filter</button>
                    </div>
                    {
                        showFilter &&
                        <div className="fixedFilter">
                            <button className="nosubmit" type="search" onClick={handleClickclear}>Clear All</button>
                            <hr />
                            <button className="filter">Show {prodDisplayModels?.length} Results</button>
                        </div>
                    }
                </div>
            </div>
            {/* Search and filter  Mobile view */}
            <div className="Mobile-search-filter">
                {
                    showSearch &&
                    <div className="Search-field">
                        <a className="back-btn" onClick={e => handleSetSearch(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                            </svg> Back
                        </a>
                        <form className="nosubmit">
                            <input className="nosubmit" type="text" placeholder="Search here"
                                value={searchQuery}
                                onChange={handleSearch}
                                onKeyDown={handleKeyDown} />
                        </form>
                    </div>
                }
                {
                    showFilter &&
                    <div className="Filter-filed">
                        <a className="back-btn" onClick={e => handleFilter(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                            </svg> Back
                        </a>
                        <div className="left-side">
                            <div className="product-filter" style={{ padding: '15px 5px' }}>
                                <h4>Category List</h4>
                                <div className="filter-sec">

                                    <Accordion defaultActiveKey={['0', 1, 2, 3, 4, 5, 6, 7, 8, 9]} alwaysOpen>
                                        {
                                            <>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Product</Accordion.Header>
                                                    <Accordion.Body>
                                                        {checkedprod?.length > 0 ? <a className="clear-btn" onClick={() => handleClear("product")}>Clear</a> : ""}
                                                        {productdisp?.map((item, index) => {
                                                            return (
                                                                <ul>
                                                                    <li>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={checkedprod.some(
                                                                                (checkedItem) =>
                                                                                    checkedItem.productName === item.productName
                                                                            )}
                                                                            onChange={() => handleCheckprod(item)}
                                                                        />
                                                                        <label>{item?.productName}</label>
                                                                    </li>
                                                                </ul>
                                                            )
                                                        })
                                                        }
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                {uniquepropname?.map((propname, propindex) => {
                                                    const valueitem = uniquepropvalue?.filter(function (item1) {
                                                        return item1?.prop_name == propname?.prop_name
                                                    })

                                                    return (
                                                        <Accordion.Item eventKey={(propindex * 1) + 1}>
                                                            <Accordion.Header>{propname?.prop_name}</Accordion.Header>
                                                            <Accordion.Body>
                                                                {checkedprop.some(
                                                                    (checkedItem) =>
                                                                        checkedItem.prop_name === propname.prop_name
                                                                ) === true ? <a onClick={() => handleClear("productprop", propname?.prop_name)} className="clear-btn">Clear</a> : ""}
                                                                {valueitem?.map((propvalue, valueindex) => {

                                                                    return (
                                                                        <ul>
                                                                            <li>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={checkedprop.some(
                                                                                        (checkedItem) =>
                                                                                            checkedItem.prop_value === propvalue.prop_value
                                                                                    )}
                                                                                    onChange={() => handleCheckprop(propvalue)}
                                                                                />
                                                                                <label>{propvalue?.prop_value}</label>
                                                                            </li>
                                                                        </ul>
                                                                    )
                                                                })
                                                                }
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    )
                                                })}
                                            </>
                                        }
                                    </Accordion>

                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </>
    )
};
export default ProductList;