import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Events2 from '../../assets/images/news2.png';
import Events3 from '../../assets/images/news33.png';
import News1 from '../../assets/images/JMC - KSA.jpg';

const NewsEvents = () => {
    const location = useLocation();

    return (
        <div className='news-and-events'>
            <div className="bread-crumbs">
                <nav>
                    <Link to="/"
                        className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
                    >
                        Home
                    </Link>
                    <span className="breadcrumb-arrow">/</span>
                    <Link to="/news-events"
                        className={location.pathname.startsWith("/news-events") ? "breadcrumb-active" : "breadcrumb-not-active"}>
                        News & Events
                    </Link>

                </nav>
            </div>
            <div className="our_products-sec latestNews">
                <div className="sec-bottom">
                    <h2 className="sec-title">Latest news & events </h2>
                </div>

                <div className="latest-news-sec">
                    <Row>
                        <Col sm={6} md={4}>
                            {/* <Link to="/news-events/news" state={{news: 1}}  
                                onClick={() => { window.scroll(0, 0); }}> */}

                            <Link
                                to={{
                                    pathname: "/news-events/news",
                                    search: "?news=1",
                                }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                                <div className="news-section">
                                    <div className="image-box">
                                        <img src={Events2} />
                                    </div>
                                    {/* <p className="news-time">NEWS • FEBRUARY 2023</p> */}
                                    <h5>Air Balance, USA signs agreement with Jersey Manufacturing Company</h5>
                                </div>
                            </Link>
                        </Col>
                        <Col sm={6} md={4}>
                        <Link
                                to={{
                                    pathname: "/news-events/news",
                                    search: "?news=2",
                                }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                                <div className="news-section">
                                    <div className="image-box">
                                        <img src={Events3} />
                                    </div>
                                    {/* <p className="news-time">Event • MARCH 2023</p> */}
                                    <h5>Pressure Independent VAVs manufactured by Jersey Manufacturing (KMC)
                                are tested and certified by AHRI</h5>
                                </div>
                            </Link>
                        </Col>
                        <Col sm={6} md={4}>
                        <Link
                                to={{
                                    pathname: "/news-events/news",
                                    search: "?news=3",
                                }}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                                <div className="news-section">
                                    <div className="image-box">
                                        <img src={News1} />
                                    </div>
                                    {/* <p className="news-time">Event • MARCH 2023</p> */}
                                    <h5>Jersey Manufacturing Co (formerly known as KMC) is now having its presence across Saudi Arabia & other GCC countries</h5>
                                </div>
                            </Link>
                        </Col>
                    </Row>
                    {/* <div className='viewmore-sec'>
                        <button className="seemore-btn"><Link onClick={() => { window.scroll(0, 0); }} to="">Load more <img src={More} /></Link></button>
                    </div> */}
                </div>
            </div>
        </div>
    )
};
export default NewsEvents