import React, { useState, useEffect } from "react";
import { Row, Col } from 'react-bootstrap';
import Next from '../../assets/images/seemore-white.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Attach from '../../assets/images/attach-file.png';
import useForm from "./useForm";
import Select from 'react-select';
import { httpHeaders } from '../../providers/dataProvider';
import urls from '../../constants';
import { useQuery } from 'react-query';
import DownloadFile from '../../assets/images/pdf.png';
import FailModal from '../Popups/FailModal';
import SuccessModal from "../Popups/SuccessModal";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'

const options = [
    { value: 'india', label: 'India' },
    { value: 'switzerland', label: 'Switzerland' },
    { value: 'southafrica', label: 'South Africa' },
    { value: 'spain', label: 'Spain' },
    { value: 'poland', label: 'Poland' },
    { value: 'qatar', label: 'Qatar' }
]


const RequestADemo = () => {
    const navigate = useNavigate();
    const requestOptions = httpHeaders();

    const [showFile, setAttachFile] = useState(false);
    const location = useLocation();
    const { ProdId, GroupId, ProdName, SubprodName } = location.state;


    const backtoDetail = () => {
        if (window.innerWidth <= 800) {
            navigate('/product-detail', {
                state: {
                    ProdId: ProdId,
                    GroupId: GroupId,
                    ProdName: ProdName,
                    SubprodName: SubprodName,
                },
            });

        }
    }

    const {
        handleFormChange,
        handleSubmit,
        formValues,
        formErrors,
        createMsg,
        successMsg,
        isSubmit,
        handleSubmitForm,
        uploadedFiles,
        handleFileEvent,
        fileLimit,
        MAX_COUNT,
        isSubmitAtt,
        setIsSubmitAtt,
        handleCountryChange,
        country,
        handleRemoveFile,
        handleInputBlur,
        errorMessage,
        failureMsg,
        handlePhoneChange,
        handleCountry,
        showInternationalFlag,
        selectedCountry,
    } = useForm();


    const { isLoading, error, data: countries } = useQuery(
        {
            queryKey: ['Countries'],
            queryFn: () =>
                fetch(urls.COUNTRY_LIST_URL, requestOptions).then((res) => res.json()),
            enabled: true,
        }
    );


    const [countryData, setCountryData] = useState([]);
    const [countrydrp, setCountrydrp] = useState([]);

    const [successpopup, setSuccessShow] = useState(false);
    const successModalClose = () => setSuccessShow(false);
    const successModalShow = () => setSuccessShow(true);
    const [failpopup, setFailShow] = useState(false);
    const failModalClose = () => setFailShow(false);
    const failModalShow = () => setFailShow(true);


    //country

    useEffect(() => {
        if (countries) setCountryData(countries)
    }, [countries])

    useEffect(() => {
        if (isSubmitAtt) {
            setAttachFile(false);
            setIsSubmitAtt(false);
        }
    }, [isSubmitAtt]);

    useEffect(() => {
        if (successMsg) {
            setAttachFile(false);
            successModalShow();
        } else {
            successModalClose();
        }
    }, [successMsg]);

    useEffect(() => {
        if (failureMsg) {
            setAttachFile(false);
            failModalShow();
        } else {
            failModalClose();
        }
    }, [failureMsg]);


    useEffect(() => {
        const getCountryData = () => {
            if (countryData) {
                const list = formatCountrydrp(countryData);
                setCountrydrp(list);
            }
        };
        getCountryData();
    }, [countryData]);


    const formatCountrydrp = (list) => {
        var te = list?.map((data, index) => ({
            label: data?.wsCountry,
            value: data?.wsCntId
        }));
        return te;
    };

    const nextModal = () => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {

            setAttachFile(true);
        }
    };

    useEffect(() => {
        nextModal();
    }, [formErrors, isSubmit]);

    const backModal = () => {
        setAttachFile(false);
    };
    return (
        <div className="Mobile-request-quote">
            {
                !showFile &&
                <div className="Form-field">
                    <a className="back-btn" onClick={backtoDetail}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg> Back
                    </a>
                    <div className="our_products-sec request-quote">
                        <div className="sec-bottom">
                            <h2 className="sec-title">Request a Quote</h2>
                            <p className="sec-bottom-title">Enter your information below</p>

                        </div>
                        <div className="contact-form">
                            <form onSubmit={handleSubmitForm}>
                                <Row>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " name="wsFirstName" onChange={handleFormChange} value={formValues?.wsFirstName} onBlur={handleInputBlur} />
                                            <label className="did-floating-label">First Name</label>
                                        </div>
                                        {formErrors.wsFirstName && <p className="form-error">{formErrors.wsFirstName}</p>}
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " name="wsLastName" onChange={handleFormChange} value={formValues?.wsLastName} onBlur={handleInputBlur} />
                                            <label className="did-floating-label">Last Name</label>
                                        </div>
                                        {formErrors.wsLastName && <p className="form-error">{formErrors.wsLastName}</p>}
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">

                                            <PhoneInput
                                                international={showInternationalFlag}
                                                defaultCountry={selectedCountry}
                                                id='MobileNo'
                                                name='wsPhone'
                                                value={formValues?.wsPhone}
                                                onChange={handlePhoneChange}
                                                onBlur={handleInputBlur}
                                                placeholder="Mobile Number"
                                                onCountryChange={handleCountry}
                                                countryCallingCodeEditable={false}
                                            />

                                        </div>
                                        {formErrors.wsPhone && <p className="form-error">{formErrors.wsPhone}</p>}
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " name="wsEmail" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wsEmail} />
                                            <label className="did-floating-label">Email</label>
                                        </div>
                                        {formErrors.wsEmail && <p className="form-error">{formErrors.wsEmail}</p>}
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " name="wsProject" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wsProject} />
                                            <label className="did-floating-label">Project</label>
                                        </div>
                                        {formErrors.wsProject && <p className="form-error">{formErrors.wsProject}</p>}
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " name="wsCustomer" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wsCustomer} />
                                            <label className="did-floating-label">Company</label>
                                        </div>
                                        {formErrors.wsCustomer && <p className="form-error">{formErrors.wsCustomer}</p>}
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " name="wsAddress1" onChange={handleFormChange} value={formValues?.wsAddress1} />
                                            <label className="did-floating-label">Address 1</label>
                                        </div>
                                        {formErrors.wsAddress1 && <p className="form-error">{formErrors.wsAddress1}</p>}
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " name="wsAddress2" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wsAddress2} />
                                            <label className="did-floating-label">Address 2</label>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            {country && <label className="did-floating-label" style={{ top: "-18px" }}>Country</label>}
                                            <Select
                                                className="did-floating-input"
                                                name="wsCountry"
                                                value={country}
                                                options={countrydrp}
                                                onChange={handleCountryChange}
                                                onBlur={handleInputBlur}
                                                placeholder="Select Country"
                                                isSearchable
                                            />
                                        </div>
                                        {formErrors.country && <p className="form-error">{formErrors.country}</p>}
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " name="wsState" onChange={handleFormChange} value={formValues?.wsState} />
                                            <label className="did-floating-label">State/Providence</label>
                                        </div>
                                        {formErrors.wsState && <p className="form-error">{formErrors.wsState}</p>}
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " name="wsCity" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wsCity} />
                                            <label className="did-floating-label">City</label>
                                        </div>
                                        {formErrors.wsCity && <p className="form-error">{formErrors.wsCity}</p>}
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " name="wsZipCode" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wsZipCode} />
                                            <label className="did-floating-label">Zip/Postal Code</label>
                                        </div>
                                        {formErrors.wsZipCode && <p className="form-error">{formErrors.wsZipCode}</p>}
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " name="wsMessage" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wsMessage} />
                                            <label className="did-floating-label">Message</label>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={7} className="submit-sec" onClick={() => {window.scroll(0, 0);}}>
                                    {Object.keys(formErrors).length === 0 ? (
                                        <button type="submit" className="btn submit-btn" onClick={nextModal}>Next <img src={Next} /></button>
                                    ) : (
                                        <button type="submit" className="btn submit-btn" onClick={handleSubmitForm} >Next <img src={Next} /></button>
                                    )}
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </div>
                </div>
            }
            {
                showFile &&
                <div className="File-field">
                    <a className="back-btn" onClick={backModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg> Back
                    </a>
                    <div className="our_products-sec request-quote">
                        <div className="sec-bottom">

                            <h2 className="sec-title">Attach file</h2>
                            <p className="sec-bottom-title">To help expedite the quote process, include attachments about your product or project request like a photo
                                equipment schedule or building schematic.</p>

                        </div>
                        <form className="form-container" onSubmit={handleSubmit}>
                        <div className="upload-files-container">
                            <div className="drag-file-area">
                                {uploadedFiles.length > 0 ? <>
                                    {uploadedFiles.map((file, index) => (
                                        <div key={index} className="uploaded-box">
                                            <a href={file.WsAttachUrl} target="_blank" rel="noopener noreferrer">
                                                <img src={DownloadFile} alt="Download" />
                                                <span>
                                                    {file.WsAttachName}
                                                </span>
                                            </a>

                                            <button onClick={() => handleRemoveFile(index)} className="close-img">
                                                X
                                            </button>
                                        </div>
                                    ))}
                                    
                                    </> : <>
                                        <img src={Attach} onChange={handleFileEvent} />
                                        <h3 className="dynamic-message" onChange={handleFileEvent}>Select a file to import</h3>
                                        <label className="label">
                                            excel, pdf, docx{" "}
                                            <span className="browse-files">
                                                <input
                                                    type="file"
                                                    name="file"
                                                    className="default-file-input"
                                                    onChange={handleFileEvent}
                                                    accept="application/msword, application/pdf, application/vnd.ms-excel"
                                                />
                                            </span>
                                        </label>
                                    </>
                                    }

                                    {fileLimit && (
                                        <div className="invalid-feedback">
                                            File limit exceeded. Maximum of {MAX_COUNT} files are allowed.
                                        </div>
                                    )}
                                </div>
                            </div>
                        </form>
                        {errorMessage && <div className="form-error">{errorMessage}</div>}
                    </div>
                    <button type="submit" className="btn submit-btn" onClick={handleSubmit} >Submit <img src={Next} /></button>
                </div>

            }


            {/*Popup success message*/}
            <SuccessModal successpopup={successpopup} successModalClose={successModalClose} successMsg={successMsg} />

            <FailModal failpopup={failpopup} failModalClose={failModalClose} failureMsg={failureMsg} />
        </div>


    )
};

export default RequestADemo