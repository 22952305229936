import React, { useState, useEffect } from "react";
import { BrowserRouter, Outlet, Route, Routes, Link } from "react-router-dom";
import "../assets/css/mainpage.css";
import "../assets/css/responsive.css";
import Header from "./Mainpage/Header";
import Footer from "./Mainpage/Footer";

const MainPage = () => {
  return (
    <div className="page">
      <div className="background bgWrapper">
        
        <header className="headerWrapper">
          <Header />
        </header>
        <a href="/contact-us/#contactform">
        <div className="floating-chat enter">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16">
            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
          </svg>
          <span>Send message</span>
        </div>
        </a>
        <div className="content-sec">
            <Outlet />
        </div>
        <div className="footer-sec">
          <div className="footer-wrapper">
            <Footer />
          </div>
          <span className="HomeSubsidiaries_bg_line__OTckB footer ratio ratio-1x1">
          <span className="SvgLineBg_bg_line__Aq2b0 SvgLineBg_bg_line_flip__l2Jky">
          <svg xmlns="http://www.w3.org/2000/svg" width="1478.05" height="1478.05" viewBox="0 0 1478.05 1478.05">
            <g id="Group_67019" data-name="Group 67019" transform="translate(713.217 1478.05) rotate(-133)">
              <g id="Group_136691" data-name="Group 136691" transform="translate(0)">
                <path id="Path_110895" data-name="Path 110895" d="M0,20.079A20.078,20.078,0,1,1,20.079,40.159,20.079,20.079,0,0,1,0,20.079Z" transform="translate(502.167 503.45)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" opacity="0"/>
                <path id="Path_110896" data-name="Path 110896" d="M13.386,0C205.71,3.7,358.623,162.6,354.927,354.927S192.324,700.164,0,696.468" transform="translate(514.266 174.442)" fill="none" stroke="#274780" strokeMiterlimit="10" strokeWidth="1"/>
                <path id="Path_110897" data-name="Path 110897" d="M4.462,0C260.895,1.231,467.774,210.11,466.543,466.543S256.433,929.855,0,928.623" transform="translate(518.728 58.364)" fill="none" stroke="#274780" strokeMiterlimit="10" strokeWidth="1"/>
                <path id="Path_110898" data-name="Path 110898" d="M15.954,31.055,0,15.669,15.383,0" transform="translate(518.729 971.318)" fill="none" stroke="#617088" strokeMiterlimit="10" strokeWidth="1"/>
                <path id="Path_110899" data-name="Path 110899" d="M15.954,31.055,0,15.669,15.383,0" transform="translate(512.746 855.074)" fill="none" stroke="#2c384a" strokeMiterlimit="10" strokeWidth="1"/>
                <path id="Path_110900" data-name="Path 110900" d="M31.232,0A117.124,117.124,0,0,1,0,232.156" transform="translate(505.344 406.598)" fill="none" stroke="#274780" strokeMiterlimit="10" strokeWidth="1"/>
                <path id="Path_110901" data-name="Path 110901" d="M14.34,30.957,0,14.057,16.872,0" transform="translate(504.01 624.471)" fill="none" stroke="#2c384a" strokeMiterlimit="10" strokeWidth="1"/>
                <path id="Path_110902" data-name="Path 110902" d="M22.31,0c128.215,6.161,227.162,115.094,221,243.311S128.218,470.473,0,464.312" transform="translate(509.804 290.52)" fill="none" stroke="#274780" strokeMiterlimit="10" strokeWidth="1"/>
                <path id="Path_110903" data-name="Path 110903" d="M15.954,31.055,0,15.669,15.383,0" transform="translate(509.805 739.162)" fill="none" stroke="#2c384a" strokeMiterlimit="10" strokeWidth="1"/>
                <path id="Path_110904" data-name="Path 110904" d="M0,16.549A174.63,174.63,0,1,1,148.227,332.8" transform="translate(447.936 349.055)" fill="none" stroke="#2befc1" strokeMiterlimit="10" strokeWidth="1"/>
                <path id="Path_110905" data-name="Path 110905" d="M21.339,27.081,0,21.087,6.128,0" transform="translate(447.178 345.025)" fill="none" stroke="#2befc1" strokeMiterlimit="10" strokeWidth="1"/>
                <path id="Path_110906" data-name="Path 110906" d="M0,33.446C141.907-41.209,317.465,13.31,392.117,155.217s20.136,317.465-121.771,392.119" transform="translate(386.876 233.335)" fill="none" stroke="#00a6ef" strokeMiterlimit="10" strokeWidth="1"/>
                <path id="Path_110907" data-name="Path 110907" d="M144.4,756.587c196.485-108.377,267.912-355.516,159.534-552S0,0,0,0" transform="translate(573.88 122.908)" fill="none" stroke="#2befc1" strokeMiterlimit="10" strokeWidth="1" opacity="0.195"/>
                <path id="Path_110908" data-name="Path 110908" d="M0,67.852c251.066-142.1,569.782-53.763,711.882,197.3s53.761,569.782-197.3,711.879" transform="translate(264.758 1.284)" fill="none" stroke="#274780" strokeMiterlimit="10" strokeWidth="1"/>
                <path id="Path_110909" data-name="Path 110909" d="M0,1.433a60.721,60.721,0,1,1,26.112,118.6" transform="translate(503.965 467.431)" fill="none" stroke="#9aa5b7" strokeMiterlimit="10" strokeWidth="1"/>
                <path id="Path_110910" data-name="Path 110910" d="M17.383,30.851,0,17.1,13.777,0" transform="translate(503.608 451.376)" fill="none" stroke="#9aa5b7" strokeMiterlimit="10" strokeWidth="1"/>
                <path id="Path_110911" data-name="Path 110911" d="M21.339,27.081,0,21.087,6.128,0" transform="translate(387.139 245.783)" fill="none" stroke="#00a6ef" strokeMiterlimit="10" strokeWidth="1"/>
                <path id="Path_110912" data-name="Path 110912" d="M21.339,27.081,0,21.087,6.128,0" transform="translate(584.435 105.506) rotate(33)" fill="none" stroke="#2befc1" strokeMiterlimit="10" strokeWidth="1"/>
                <path id="Path_110913" data-name="Path 110913" d="M21.339,27.081,0,21.087.228,20.3,6.128,0" transform="translate(263.754 48.852)" fill="none" stroke="#2c384a" strokeMiterlimit="10" strokeWidth="1"/>
                <g id="Component_67_7" data-name="Component 67 – 7">
                  <rect id="Rectangle_25712" data-name="Rectangle 25712" width="1045.776" height="1045.776" fill="none"/>
                </g>
              </g>
            </g>
          </svg>
          </span>
        </span>
        </div>
       


      </div>
    </div>
  );
};

export default MainPage;
