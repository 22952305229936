import React, { useState, useEffect } from "react";
import OtpVerifyModal from './OtpVerifyModal';
import { Row, Col, Modal, Popover } from 'react-bootstrap';
import Next from '../../assets/images/seemore-white.svg';
import {
    isRequired,
    isValidEmail,
    isValidPhone,
    isTextOnly,
    checknull
} from '../../utils/Validate';
import { GetOne ,CreateEntry} from '../../providers/dataProvider';
import 'react-phone-number-input/style.css'
import PhoneInput,{isValidPhoneNumber} from 'react-phone-number-input';



const defaultValues = {
    wsdConName: "",
    wsdConComName: "",
    wsdConPhone: "",
    wsdConEmail: "",
    wsdOTPVerified: false,
    wsdCreatedOn: new Date()
}
const DownloadDocument = (props) => {
    const { documentshow, documentmodalClose, setDocumentShow, handleDownload, attachment } = props
    const [formValues, setFormValues] = useState(defaultValues)
    const [otpMessage, setOtpMessage] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false)
    const [otpshow, setOtpShow] = useState(false);
    const [createMsg, setCreateMsg] = useState(null);
    const [phoneValue, setPhoneValue] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const otpmodalClose = () => setOtpShow(false);
    const otpmodalShow = () => {
        setDocumentShow(false);
        setOtpShow(true);
    }


    useEffect(()=>{
        setPhoneValue(null);
        setFormErrors({});
        setErrorMessage(null);
        setFormValues(defaultValues);
    },[documentshow])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value })

        // re-run the validation check on the changed field value
        const errors = Validation({
            ...formValues,
            [name]: value,
        });
        // update the formErrors state
        setFormErrors({
            ...formErrors,
            [name]: errors[name]
        });
    }

    const handleInputBlur = (e) => {
        if (e.target) {
            const { name, value } = e.target;

            const errors = Validation({
                ...formValues,
                [name]: value,
            });

            setFormErrors({
                ...formErrors,
                [name]: errors[name],
            });
        }
    };

    useEffect(() => {
        if (phoneValue !== null) {
            setFormValues({
                ...formValues,
                "wsdConPhone": phoneValue,
            });

            // re-run the validation check on the changed field value
            const errors = Validation({
                ...formValues,
                "wsdConPhone": phoneValue,
            });
            // update the formErrors state
            setFormErrors({
                ...formErrors,
                "wsdConPhone": errors["wsdConPhone"]
            });
        }

    }, [phoneValue])

    const Validation = (values) => {
        const errors = {};

        if (!isRequired(values.wsdConName)) {
            errors.wsdConName = 'Name is required';
        } else if (!isTextOnly(values.wsdConName)) {
            errors.wsdConName = 'Name can only contain alphabets';
        }

        if (isRequired(values.wsdConPhone)) {
           if (values?.wsdConPhone?.length>=4 && !isValidPhoneNumber(values.wsdConPhone)) {
            errors.wsdConPhone = 'Phone number is not valid';
           }
        }

        if (!isRequired(values.wsdConEmail)) {
            errors.wsdConEmail = 'Email is required';
        } else if (!isValidEmail(values.wsdConEmail)) {
            errors.wsdConEmail = 'Email is not valid';
        }

        if (!isRequired(values.wsdConComName)) {
            errors.wsdConComName = 'Company name is required';
        }
        return errors;
    }

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {

            const formValuesCopy = {...formValues}
            if(!isValidPhoneNumber(formValuesCopy["wsdConPhone"]))
            {
                formValuesCopy["wsdConPhone"] = "";
            }
            CreateEntry(
                'DownloadContacts',
                formValuesCopy,
                setCreateMsg,
                'Success',
                'Failed'
            );

        }
        setIsSubmit(false);
    }, [formErrors]);


    useEffect(() => {
        if (createMsg?.message === "Success") {
            const params = { id: formValues.wsdConEmail };
            GetOne("sendotp", params, setOtpMessage)
        }
        if(createMsg?.message === "Failed")
        {
            setErrorMessage("Document Form Submission Failed")
        }
    }, [createMsg])

    useEffect(() => {
        if (checknull(otpMessage?.message) === "success") {
            otpmodalShow();
        }

    }, [otpMessage])

    const submitForm = (event) => {
        event.preventDefault();
        setFormErrors(Validation(formValues));
        setIsSubmit(true);
    }

    const closepopup = () =>{
        setErrorMessage(null);
    }
    return (
        <>
            <Modal centered show={documentshow} onHide={documentmodalClose} className="new-demo-modal">
                <Modal.Header>
                    <Modal.Title>Download Document</Modal.Title>
                    <button type="button" className="btn-close" aria-label="Close" onClick={documentmodalClose}>X</button>
                </Modal.Header>


                <Modal.Body className="show-grid">
                    <p>Enter your information below</p>
                    <div className="contact-form">
                        <Row>
                            <Col sm={12} md={12}>
                                <div className="did-floating-label-content">
                                    <input className="did-floating-input" type="text" placeholder=" " onChange={handleChange} onBlur={handleInputBlur} name="wsdConName" />
                                    <label className="did-floating-label">Name</label>
                                </div>
                                {formErrors?.wsdConName && <p className="form-error">{formErrors?.wsdConName}</p>}
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="did-floating-label-content">
                                {formValues.wsdConPhone && <label className="did-floating-label" style={{top:"-18px"}}>Phone Number</label>}
                                <PhoneInput
                                                    id='wsdConPhone'
                                                    international
                                                    countryCallingCodeEditable={false}
                                                   // error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                                    name='wsdConPhone'
                                                    placeholder="Phone Number"
                                                    defaultCountry={'QA'}
                                                    country={'in'}
                                                    value={formValues.wsdConPhone || ''}
                                                  //  onBlur={(value)=>{handleInputBlur(value)}}
                                                    onChange={setPhoneValue}
                                                    enableSearch
                                                    searchPlaceholder="Search Country"
                                                />

                                    {/* <input className="did-floating-input" type="text" placeholder=" " onChange={handleChange} onBlur={handleInputBlur} name="wsdConPhone" />
                                    <label className="did-floating-label">Phone Number</label> */}
                                </div>
                                {formErrors?.wsdConPhone && <p className="form-error" style={{marginLeft: "38px"}}>{formErrors?.wsdConPhone}</p>}
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="did-floating-label-content">
                                    <input className="did-floating-input" type="email" placeholder=" " onChange={handleChange} onBlur={handleInputBlur} name="wsdConEmail" />
                                    <label className="did-floating-label">Email</label>
                                </div>
                                {formErrors?.wsdConEmail && <p className="form-error">{formErrors?.wsdConEmail}</p>}
                            </Col>
                            <Col sm={12} md={12}>
                                <div className="did-floating-label-content">
                                    <input className="did-floating-input" type="text" placeholder=" " onChange={handleChange} onBlur={handleInputBlur} name="wsdConComName" />
                                    <label className="did-floating-label">Company name</label>
                                </div>
                                {formErrors?.wsdConComName && <p className="form-error">{formErrors?.wsdConComName}</p>}
                            </Col>
                            <Col sm={12} md={7} className="submit-sec">
                                <button className="btn submit-btn" onClick={submitForm}>Next <img src={Next} /></button>
                            </Col>
                            <Col sm={12} md={12}>
                                {errorMessage &&<div className='alert-toast'>
                                    <p className="form-error">{errorMessage}</p>
                                    <button className="close-img" onClick={() => closepopup()}>X</button>
                                </div>}
                            </Col>
                        </Row>
                    </div>
                   
                </Modal.Body>
            </Modal>
            {/* Otp verify modal */}
            <OtpVerifyModal
                otpshow={otpshow}
                otpmodalClose={otpmodalClose}
                otpMessage={otpMessage}
                setOtpShow={setOtpShow}
                handleDownload={handleDownload}
                attachment={attachment}
            />
        </>
    )
}


export default DownloadDocument