import React from "react";
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NotFound from "../Common/NotFound";
import NoImage from '../../assets/images/no-image.svg'

function ProductFilterList({ filterData, Id, Name }) {
    return (
        <>
            <Col md={12} className="list-items">
                {filterData?.length > 0 ? (
                    <Row>
                        {filterData.map((prod, key) => (
                            <Col md={4} sm={3} xs={6} lg={4} className="pl-1 pr-1" key={prod?.modelsdetails?.wsmMdlId}>
                                <Link
                                    to={{ pathname: `/product-detail`, search: `?ProdId=${prod?.modelsdetails?.wsmMdlId}&GroupId=${Id}&ProdName=${Name}&SubprodName=${prod?.modelsdetails?.wsmMdlName}` }}
                                    onClick={() => { window.scroll(0, 0); }}>
                                    <div className="carousel__item right">
                                        {prod?.modelsdetails?.tblWsProdMdlImages[0]?.wsmImgUrl == undefined ?
                                            <div className="no-image">
                                                <img width="100%" src={NoImage} />
                                                <p>No Image Available</p>
                                            </div>
                                            :
                                            <div className="picture-box">
                                                <img width="100%" src={prod?.modelsdetails?.tblWsProdMdlImages[0]?.wsmImgUrl} />
                                            </div>
                                        }
                                        <div className="carousel__caption">
                                            <h5>{prod?.modelsdetails?.wsmMdlName}</h5>
                                            <p title={prod?.modelsdetails?.wsmMdlSrtDesc}>{prod?.modelsdetails?.wsmMdlSrtDesc.split(",")[0]}</p>
                                            <p title={prod?.modelsdetails?.wsmMdlSrtDesc}>{prod?.modelsdetails?.wsmMdlSrtDesc.split(",")[1]}</p>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                        ))}
                    </Row>
                ) : (
                    <NotFound />
                )}
            </Col>
        </>
    )
}
export default ProductFilterList