import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap';
import pdf from '../../assets/images/pdf.png';
import Download from '../../assets/images/download-blue.png'
import Next from '../../assets/images/seemore-white.svg';
import { checknull } from '../../utils/Validate'
import FetchData, { httpHeaders } from "../../providers/dataProvider";
import Select from 'react-select';
import NotFound from "../Common/NotFound";
import { Pagination } from "../Common/Pagination"
import Spinner from "../Common/Spinner";
import urls from "../../constants";
import { saveAs } from 'file-saver';
import DownloadDocument from './DownloadDocument';
import {
    useQuery
} from 'react-query'

const options = [
    { value: 'india', label: 'India' },
    { value: 'switzerland', label: 'Switzerland' },
    { value: 'southafrica', label: 'South Africa' },
    { value: 'spain', label: 'Spain' },
    { value: 'poland', label: 'Poland' },
    { value: 'qatar', label: 'Qatar' }
]

const DownloadCenter = () => {
    const [showSearch, handleSearch] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [showFilter, handleFilter] = useState(false);
    const [downloadshow, setDownloadShow] = useState(false);
    const downloadmodalClose = () => setDownloadShow(false);
    const downloadmodalShow = () => setDownloadShow(true);
    const [showDocument, setShowDocument] = useState(false);
    const [downloadData, setDownloadData] = useState([]);
    const [category, setCategory] = useState([])
    const [subCategory, setSubCategory] = useState([])
    const [type, setType] = useState([])
    const [checked, setChecked] = useState([])
    const [searchEnter, setSearchEnter] = useState(false);
    const [downloadcenter, setDownloadcenter] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [count, setCount] = useState(10);
    const [attachment, setAttachment] = useState(null);
    const [documentshow, setDocumentShow] = useState(false);
    const documentmodalClose = () => setDocumentShow(false);
    const documentmodalShow = (attachment) => {
        if ((document.cookie).indexOf("emailverify") < 0) {
            setAttachment(attachment)
            setDocumentShow(true);
        }
        else {
            handleDownload(attachment.wspPatUrl, attachment.wspPatName)
        }
    }
    const [postData, setPostData] = useState({
        GrpIds: [],
        SubGrpIds: [],
        Type: [],
        SearchKey: "",
        PaginationParameters:
        {
            PageNumber: 1,
            PageSize: 10
        }
    })

    const handlePageChange = (page) => {
        setPageNumber(page);
        setPostData({
            ...postData,
            PaginationParameters: {
                ...postData.PaginationParameters,
                PageNumber: page
            }
        })
    };

    const handleDownload = (url, name) => {
        const fileUrl = url;
        saveAs(fileUrl, `${name}.pdf`);
    };

    useEffect(() => {
        setChecked([]);
    }, [])

    const setDownloadDemo = () => {
        if (window.innerWidth <= 800) {
            setShowDocument(true)
        }
        else {
            downloadmodalShow();
        }
    }
    const backtoDownload = () => {
        setShowDocument(false)
    }

    const requestOptions1 = httpHeaders();


    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(postData)
    };


    const { isLoading, error, data, isFetching, refetch } = useQuery({
        queryKey: ['downloadcenter', pageNumber],
        queryFn: () =>
            fetch(`${urls.DOWNLOAD_CENTER_URL}`, requestOptions).then(
                (res) => res.json(),
            ),
        keepPreviousData: true,
    })

    const { data: downloadcenterfilter } = useQuery({
        queryKey: ['downloadcenterfilter'],
        queryFn: () =>
            fetch(`${urls.DOWNLOAD_CENTER_FILTER_URL}`, requestOptions1).then(
                (res) => res.json(),
            ),
    })



    //const { data: downloadcenter, loading, error } = FetchData("downloadcenter");
    //const { data: downloadcenterfilter } = FetchData("downloadcenterfilter");

    useEffect(() => {
        let categoryitem = checked.map(item => item.grouptype === "category" && item.Id);
        let subcategoryitem = checked.map(item => item.grouptype === "subcategory" && item.Id);
        let ptypeitem = checked.map(item => item.grouptype === "type" && item.documentGroup);
        categoryitem = categoryitem.filter((item) => item)
        subcategoryitem = subcategoryitem.filter((item) => item)
        ptypeitem = ptypeitem.filter((item) => item)

        setPostData({
            GrpIds: categoryitem,
            SubGrpIds: subcategoryitem,
            Type: ptypeitem,
            SearchKey: searchQuery,
            PaginationParameters: {
                PageNumber: 1,
                PageSize: 10
            }
        })
    }, [checked])

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value)
        setPostData({
            ...postData,
            SearchKey: e.target.value,
            PaginationParameters: {
                ...postData.PaginationParameters
            }
        })
    }

    useEffect(() => {
        refetch()
    }, [postData.GrpIds, postData.SubGrpIds, postData.Type, postData.PaginationParameters.PageSize, postData.SearchKey])


    useEffect(() => {
        setPostData({
            ...postData,
            PaginationParameters: {
                PageNumber: pageNumber,
                PageSize: count
            }
        })
    }, [count])

    useEffect(() => {
        setDownloadcenter(data?.data)
    }, [data])

    useEffect(() => {
        setDownloadData(downloadcenter)
    }, [downloadcenter])

    useEffect(() => {
        let categoryarr = [];
        let subCategoryarr = [];
        let typearr = [];
        downloadcenterfilter?.map((item, index) => {

            const findCategory = categoryarr.find((element) => element.Id == item.groupId && element.grouptype === "category")
            if (checknull(findCategory) === "") {
                categoryarr.push({ Id: item.groupId, groupName: item.groupName, grouptype: "category" })
            }

            let categoryitem = checked.map(item => item.grouptype === "category" && item.Id);
            categoryitem = categoryitem.filter((item) => item)
            if (categoryitem?.length > 0) {
                if (categoryitem.includes(item.groupId)) {
                    const findSubCategory = subCategoryarr.find((element) => element.Id == item.subGroupId && element.grouptype === "subcategory")
                    if (checknull(findSubCategory) === "") {
                        subCategoryarr.push({ Id: item.subGroupId, subGroupName: item.subGroupName, grouptype: "subcategory", categoryName: item.groupName })
                    }
                }
            }
            else {
                const findSubCategory = subCategoryarr.find((element) => element.Id == item.subGroupId && element.grouptype === "subcategory")
                if (checknull(findSubCategory) === "") {
                    subCategoryarr.push({ Id: item.subGroupId, subGroupName: item.subGroupName, grouptype: "subcategory", categoryName: item.groupName })
                }
            }

            let subcategoryitem = checked.map(item => item.grouptype === "subcategory" && item.Id);
            subcategoryitem = subcategoryitem.filter((item) => item)

            if (subcategoryitem?.length > 0) {
                if (categoryitem.includes(item.groupId)) {
                    const findtype = typearr.find((element) => element.documentGroup == item.type && element.grouptype === "type")
                    if (checknull(findtype) === "") {
                        typearr.push({ Id: (index * 1) + 1, documentGroup: item.type, grouptype: "type", categoryName: item.groupName, subCatName: item.subGroupName })
                    }
                }
            }
            else {
                const findtype = typearr.find((element) => element.documentGroup == item.type && element.grouptype === "type")
                if (checknull(findtype) === "") {
                    typearr.push({ Id: (index * 1) + 1, documentGroup: item.type, grouptype: "type", categoryName: item.groupName, subCatName: item.subGroupName })
                }
            }
        })
        setCategory(categoryarr)
        setSubCategory(subCategoryarr)
        setType(typearr)

    }, [downloadcenterfilter, checked])


    const handleCheckItem = (item) => {
        const categoryitem = checked.find(chkitem => chkitem.grouptype === item.grouptype && item.Id === chkitem.Id);
        let value = [];
        if (checknull(categoryitem) !== "") {
            if (item?.grouptype === "category") {
                value = checked.filter(function (filteritem) {
                    return (filteritem.Id !== item.Id || filteritem.grouptype !== item.grouptype)
                        && (filteritem.categoryName !== item.groupName)
                })
            }
            else if (item?.grouptype === "subcategory") {
                value = checked.filter(function (filteritem) {
                    return (filteritem.Id !== item.Id || filteritem.grouptype !== item.grouptype)
                        && (filteritem.subCatName !== item.subGroupName)
                })
            }
            else {
                value = checked.filter(function (filteritem) {
                    return filteritem.Id !== item.Id || filteritem.grouptype !== item.grouptype
                })
            }
            setChecked(value)
        }
        else {
            setChecked([...checked, item])
        }
    }

    return (
        <>
            {
                !showDocument &&
                <div className="download-center">
                    <div className={`our_products-sec downloadcenter ${showSearch || showFilter ? 'd-none' : ''}`}>
                        <div className="container">
                            <p className="sec-top-title">BROWSE OUR DATABASE</p>
                            <div className="sec-bottom">
                                <h2 className="sec-title">Product & Technical Data Sheets, Submittals, Installation Instructions, Technical Articles And More.</h2>
                            </div>
                        </div>
                    </div>
                    <div className="our_products-sec searchproduct">
                        <Row>
                            <Col md={3} lg={3} className="left-side">
                                <div className="product-filter">
                                    <div className="filter-sec">
                                        <h4>Search Products</h4>
                                        <input className="nosubmit"
                                            type="search"
                                            placeholder="Search Model"
                                            value={searchQuery}
                                            // onKeyDown={handleSearchdc} 
                                            onChange={handleSearchChange}
                                        />
                                        <div className="filter-category">
                                            <h4>Filter By Category</h4>
                                            <Accordion defaultActiveKey={['0', '1', '2']} alwaysOpen>
                                                <Accordion.Item eventKey="0">
                                                    {/* <p className="header-title">Category</p> */}
                                                    <Accordion.Header className="header-title">Category</Accordion.Header>

                                                    <Accordion.Body>
                                                        <ul>
                                                            {category.map((cat_item, cat_index) => {
                                                                return (
                                                                    <li> <input type="checkbox" checked={checked.map(item => item.grouptype === "category" && item.Id).includes(cat_item?.Id)}
                                                                        onChange={() => handleCheckItem(cat_item)} />
                                                                        <label>{cat_item.groupName}</label>
                                                                    </li>
                                                                )
                                                            })}

                                                        </ul>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    {/* <p className="header-title">Sub Category</p> */}
                                                    <Accordion.Header>Sub Category</Accordion.Header>

                                                    <Accordion.Body>
                                                        <ul>
                                                            {subCategory.map((sub_item, sub_index) => {
                                                                return (
                                                                    <li> <input type="checkbox" checked={checked.map(item => item.grouptype === "subcategory" && item.Id).includes(sub_item?.Id)}
                                                                        onChange={() => handleCheckItem(sub_item)}
                                                                    />
                                                                        <label>{sub_item.subGroupName}</label>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    {/* <p className="header-title">Type</p> */}
                                                    <Accordion.Header>Type</Accordion.Header>

                                                    <Accordion.Body>
                                                        <ul>
                                                            {type.map((type_item, type_index) => {
                                                                return (
                                                                    <li> <input type="checkbox" checked={checked.map(item => item.grouptype === "type" && item.Id).includes(type_item?.Id)}
                                                                        onChange={() => handleCheckItem(type_item)} />
                                                                        <label>{type_item.documentGroup}</label>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={9} lg={9} className="right-side pl-1">
                                {downloadData?.length > 0 ? (<><div className="table-area">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Model</th>
                                                <th>Category</th>
                                                <th>Sub Category</th>
                                                <th>Type</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {downloadData?.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <div className="title">
                                                                <img src={pdf} />
                                                                <p>{item?.documentName}
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td>{item?.modelName}</td>
                                                        <td>{item?.groupName}</td>
                                                        <td>{item?.subGroupName}</td>
                                                        <td>{item?.documentGroup}</td>
                                                        <td>
                                                            <div className="download"
                                                            //</td>onClick={downloadmodalShow}
                                                            >
                                                                <img src={Download} />
                                                                <a onClick={() => { documentmodalShow({ wspPatName: item?.documentName, wspPatUrl: item?.documentUrl }); }} title={item?.documentName}><p>Download</p></a>
                                                                {/* <a href={item?.documentUrl} target="_blank"><p>Download</p></a> */}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                    {/* pagination-sec start */}
                                    <section className='pagination-sec'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='pagination-d-flex'>
                                                    {downloadData.length > 0 && (
                                                        <div className='d-flex justify-content-end ml-4 align-items-center'>
                                                            <p className='mr-2'>Show</p>
                                                            <select
                                                                className='form-control'
                                                                value={count}
                                                                onChange={(e) => setCount(Number(e.target.value))}
                                                            >
                                                                {[10, 25, 50].map((Count) => (
                                                                    <option key={Count} value={Count}>
                                                                        {Count}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    )}
                                                    <nav aria-label='Page navigation example'>
                                                        <Pagination
                                                            className='pagination-bar pl-0 mb-0'
                                                            currentPage={pageNumber}
                                                            totalCount={data?.totalRecords}
                                                            pageSize={count}
                                                            onPageChange={handlePageChange}
                                                        />
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    {/* <div>
                                <button onClick={()=>{setPageNumber(page=>page-1)}} disabled={pageNumber===1}>Prev</button>
                                <button onClick={()=>{setPageNumber(page=>page+1)}} disabled={pageNumber===data?.totalPages}>{pageNumber}Next{data?.totalPages}</button>
                                </div> */}
                                </>)
                                    : <NotFound />}
                            </Col>
                        </Row>
                    </div>
                    <div className={`our_products-sec mobile-searchproduct ${showSearch || showFilter ? 'new-active' : ''}`}>
                        {
                            !showSearch && !showFilter &&
                            <Row>
                                {
                                    downloadData?.length > 0 ?
                                        (downloadData?.map((item, index) => {
                                            return (
                                                <Col md={12} xs={12}>
                                                    <div className="download-box">
                                                        <div className="title">
                                                            <img src={pdf} />
                                                            <p>{item?.documentName}
                                                            </p>
                                                        </div>
                                                        <div className="download-text"><p>Model: </p><p>{item?.modelName}</p></div>
                                                        <div className="download-text"><p>Category: </p><p>{item?.groupName}</p></div>
                                                        <div className="download-text"><p>Sub Category: </p><p>{item?.subGroupName}</p></div>
                                                        <div className="download-text"><p>Type: </p><p>{item?.documentGroup}</p></div>
                                                        <div className="download-text">
                                                            <p></p>
                                                            <div className="download"
                                                            //onClick={setDownloadDemo}
                                                            >
                                                                <img src={Download} />
                                                                <a onClick={() => { documentmodalShow({ wspPatName: item?.documentName, wspPatUrl: item?.documentUrl }); }} title={item?.documentName}><p>Download</p></a>
                                                                {/* <a href={item?.documentUrl} target="_blank"> <p>Download</p></a> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })) : <NotFound />
                                }

                            </Row>
                        }
                        <div className="mobile-filter">
                            {
                                !showSearch && !showFilter &&
                                <div className="fixedDiv" onClick={() => { window.scroll(0, 0) }}>
                                    <button className="nosubmit" type="search" onClick={handleSearch}>Search</button>
                                    <hr />
                                    <button className="filter" onClick={handleFilter}>Filter By Category</button>
                                </div>
                            }
                            {
                                showFilter &&
                                <div className="fixedFilter">
                                    <button className="nosubmit" type="search" onClick={() => setChecked([])}>Clear All</button>
                                    <hr />
                                    <button className="filter">Show {data?.totalRecords} Results</button>
                                </div>
                            }
                        </div>

                        {/* Search and filter  Mobile view */}
                        <div className="Mobile-search-filter">
                            {
                                showSearch &&
                                <div className="Filter-filed Search-field">
                                    <a className="back-btn" onClick={e => handleSearch(false)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                        </svg> Back
                                    </a>
                                    <div className="left-side">
                                        <div className="product-filter" style={{ padding: '15px 5px' }}>
                                            <input className="nosubmit"
                                                type="search"
                                                placeholder="Search Model"
                                                value={searchQuery}
                                                // onKeyDown={handleSearchdc} 
                                                onChange={handleSearchChange}
                                            // onChange={(e) => setSearchQuery(e.target.value)}
                                            />

                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                showFilter &&
                                <div className="Filter-filed">
                                    <a className="back-btn" onClick={e => handleFilter(false)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                        </svg> Back
                                    </a>
                                    <div className="left-side">
                                        <div className="product-filter category" style={{ padding: '15px 5px' }}>
                                            <h4>Filters</h4>
                                            <div className="filter-sec">
                                                <Accordion defaultActiveKey={['0', '1', '2']} alwaysOpen>
                                                    <Accordion.Item eventKey="0">
                                                        {/* <p className="header-title">Category</p> */}
                                                        <Accordion.Header className="header-title">Category</Accordion.Header>

                                                        <Accordion.Body>
                                                            <ul>
                                                                {category.map((cat_item, cat_index) => {
                                                                    return (
                                                                        <li> <input type="checkbox" checked={checked.map(item => item.grouptype === "category" && item.Id).includes(cat_item?.Id)}
                                                                            onChange={() => handleCheckItem(cat_item)} />
                                                                            <label>{cat_item.groupName}</label>
                                                                        </li>
                                                                    )
                                                                })}

                                                            </ul>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="1">
                                                        {/* <p className="header-title">Sub Category</p> */}
                                                        <Accordion.Header>Sub Category</Accordion.Header>

                                                        <Accordion.Body>
                                                            <ul>
                                                                {subCategory.map((sub_item, sub_index) => {
                                                                    return (
                                                                        <li> <input type="checkbox" checked={checked.map(item => item.grouptype === "subcategory" && item.Id).includes(sub_item?.Id)}
                                                                            onChange={() => handleCheckItem(sub_item)}
                                                                        />
                                                                            <label>{sub_item.subGroupName}</label>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="2">
                                                        {/* <p className="header-title">Type</p> */}
                                                        <Accordion.Header>Type</Accordion.Header>

                                                        <Accordion.Body>
                                                            <ul>
                                                                {type.map((type_item, type_index) => {
                                                                    return (
                                                                        <li> <input type="checkbox" checked={checked.map(item => item.grouptype === "type" && item.Id).includes(type_item?.Id)}
                                                                            onChange={() => handleCheckItem(type_item)} />
                                                                            <label>{type_item.documentGroup}</label>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
            {
                showDocument &&
                <div className="Mobile-request-quote">
                    <div className="Form-field">
                        <a className="back-btn" onClick={backtoDownload}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                            </svg> Back
                        </a>
                        <div className="our_products-sec request-quote">
                            <div className="sec-bottom">
                                <h2 className="sec-title">Download Document</h2>
                                <p className="sec-bottom-title">Enter your information below</p>

                            </div>
                            <div className="contact-form">
                                <Row>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " />
                                            <label className="did-floating-label">First Name</label>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " />
                                            <label className="did-floating-label">Last Name</label>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " />
                                            <label className="did-floating-label">Phone Number</label>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " />
                                            <label className="did-floating-label">Email</label>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " />
                                            <label className="did-floating-label">Project</label>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " />
                                            <label className="did-floating-label">Company</label>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " />
                                            <label className="did-floating-label">Address 1</label>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " />
                                            <label className="did-floating-label">Address 2</label>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            {/* <input className="did-floating-input" type="text" placeholder=" " />
                                                <label className="did-floating-label">Country</label> */}
                                            <Select options={options} className="did-floating-input" placeholder="Country" />
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " />
                                            <label className="did-floating-label">State/Providence</label>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " />
                                            <label className="did-floating-label">City</label>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " />
                                            <label className="did-floating-label">Zip/Postal Code</label>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12}>
                                        <div className="did-floating-label-content">
                                            <input className="did-floating-input" type="text" placeholder=" " />
                                            <label className="did-floating-label">Message</label>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={7} className="submit-sec">
                                        <button type="submit" className="btn submit-btn">Submit <img src={Next} /></button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {/* Download document modal */}
            <Modal centered show={downloadshow} onHide={downloadmodalClose} className="new-demo-modal">
                <Modal.Header>
                    <Modal.Title>Download document</Modal.Title>
                    <button type="button" className="btn-close" aria-label="Close" onClick={downloadmodalClose}>X</button>
                </Modal.Header>


                <Modal.Body className="show-grid">
                    <p>Enter your information below</p>
                    <div className="contact-form">
                        <Row>
                            <Col sm={12} md={6}>
                                <div className="did-floating-label-content">
                                    <input className="did-floating-input" type="text" placeholder=" " />
                                    <label className="did-floating-label">First Name</label>
                                </div>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="did-floating-label-content">
                                    <input className="did-floating-input" type="text" placeholder=" " />
                                    <label className="did-floating-label">Last Name</label>
                                </div>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="did-floating-label-content">
                                    <input className="did-floating-input" type="text" placeholder=" " />
                                    <label className="did-floating-label">Phone Number</label>
                                </div>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="did-floating-label-content">
                                    <input className="did-floating-input" type="text" placeholder=" " />
                                    <label className="did-floating-label">Email</label>
                                </div>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="did-floating-label-content">
                                    <input className="did-floating-input" type="text" placeholder=" " />
                                    <label className="did-floating-label">Project</label>
                                </div>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="did-floating-label-content">
                                    <input className="did-floating-input" type="text" placeholder=" " />
                                    <label className="did-floating-label">Company</label>
                                </div>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="did-floating-label-content">
                                    <input className="did-floating-input" type="text" placeholder=" " />
                                    <label className="did-floating-label">Address 1</label>
                                </div>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="did-floating-label-content">
                                    <input className="did-floating-input" type="text" placeholder=" " />
                                    <label className="did-floating-label">Address 2</label>
                                </div>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="did-floating-label-content">
                                    {/* <input className="did-floating-input" type="text" placeholder=" " />
                                    <label className="did-floating-label">Country</label> */}
                                    <Select options={options} className="did-floating-input" placeholder="Country" />
                                </div>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="did-floating-label-content">
                                    <input className="did-floating-input" type="text" placeholder=" " />
                                    <label className="did-floating-label">State/Providence</label>
                                </div>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="did-floating-label-content">
                                    <input className="did-floating-input" type="text" placeholder=" " />
                                    <label className="did-floating-label">City</label>
                                </div>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="did-floating-label-content">
                                    <input className="did-floating-input" type="text" placeholder=" " />
                                    <label className="did-floating-label">Zip/Postal Code</label>
                                </div>
                            </Col>
                            <Col sm={12} md={12}>
                                <div className="did-floating-label-content">
                                    <input className="did-floating-input" type="text" placeholder=" " />
                                    <label className="did-floating-label">Message</label>
                                </div>
                            </Col>
                            <Col sm={12} md={7} className="submit-sec">
                                <button type="submit" className="btn submit-btn">Next <img src={Next} /></button>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Download document modal */}
            <DownloadDocument documentshow={documentshow}
                documentmodalClose={documentmodalClose}
                setDocumentShow={setDocumentShow}
                handleDownload={handleDownload}
                attachment={attachment} />
        </>
    )
};
export default DownloadCenter