import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Next from '../../assets/images/next-button.svg';
import NextBlack from '../../assets/images/next-button-black.svg';
import Upbutton from '../../assets/images/upbtn.png';
import useForm from './useForm';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'

export default function ContactData() {

    const {
        handleFormChange,
        handleSubmit,
        handlePhoneChange,
        formValues,
        formErrors,
        createMsg,
        successMsg,
        handleCountryChange,
        selectedCountry,
        handleInputBlur,
        errorMsg,
        setSuccessMsg,
        errorCount
    } = useForm();





    return (
        <div className="our_products-sec contactus">
            <Row>
                <Col sm={12} md={5}>
                    <p className="sec-top-title">CONTACT US</p>
                    <h2 className="sec-title">Its time to talk about your projects</h2>
                    <div className="contact-info"><img src={Upbutton} /><p><a href="mailto:info@jerseymfg.com">info@jerseymfg.com</a></p></div>
                </Col>
                <Col sm={12} md={7}>

                    <div className="contact-form">

                        <form onSubmit={handleSubmit}>
                            <Row>
                                <Col sm={12} md={6}>
                                    <div className="did-floating-label-content">
                                        <input className="did-floating-input" type="text" placeholder=" " name="wspConName" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wspConName} />
                                        <label className="did-floating-label">Name</label>
                                        {formErrors.wspConName && <p className="form-error">{formErrors.wspConName}</p>}
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className="did-floating-label-content">
                                        <input className="did-floating-input" type="text" placeholder=" " name="wspConComName" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wspConComName} />
                                        <label className="did-floating-label">Company Name</label>
                                        {formErrors.wspConComName && <p className="form-error">{formErrors.wspConComName}</p>}
                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="did-floating-label-content">
                                        {formValues.wspConPhone && <label className="did-floating-label" style={{ top: "-15px", color: "#81868C" }}>Phone Number</label>}
                                        <PhoneInput
                                            international
                                            countryCallingCodeEditable={false}
                                            defaultCountry="QA"
                                            id='MobileNo'
                                            name='wspConPhone'
                                            value={formValues?.wspConPhone}
                                            onChange={handlePhoneChange}
                                            onBlur={handleInputBlur}
                                            placeholder="Mobile Number"

                                        />


                                        {formErrors.wspConPhone && <p className="form-error" style={{marginLeft: "38px"}}>{formErrors.wspConPhone}</p>}
                                    </div>

                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="did-floating-label-content">
                                        <input className="did-floating-input" type="text" placeholder=" " name="wspConEmail" onChange={handleFormChange} onBlur={handleInputBlur} value={formValues?.wspConEmail} />
                                        <label className="did-floating-label">Email</label>
                                        {formErrors.wspConEmail && <p className="form-error">{formErrors.wspConEmail}</p>}
                                    </div>
                                </Col>
                                <Col sm={12} md={12}>
                                    <div className="did-floating-label-content">
                                        <input className="did-floating-input" type="text" placeholder=" " name="wspConMsg" onChange={handleFormChange} value={formValues?.wspConMsg} />
                                        <label className="did-floating-label">Message</label>
                                        {formErrors.wspConMsg && <p className="form-error">{formErrors.wspConMsg}</p>}
                                    </div>
                                </Col>

                                <Col sm={12} md={12}>
                                    <div className="did-floating-label-content agree">
                                        <div className='agree-check'>
                                            <input type="checkbox" className="did-floating-label-content" id="check2" name="privacy" checked={formValues.privacy}
                                                onChange={handleFormChange}
                                                onBlur={handleInputBlur}
                                                value="true" />
                                            <label className="did-floating-label-content form-check-label" htmlFor="check2">I agree to the <a href='/privacy-policy'>privacy policy</a> and to be contacted by Jersey Manufacturing</label>
                                        </div>
                                        {formErrors.privacy && <p className="form-error">{formErrors.privacy}</p>}
                                    </div>
                                </Col>

                                <Col sm={12} md={7} className="submit-sec">

                                    <button type="submit" className="btn submit-btn" disabled={errorCount !== 0}>
                                        Send Message <img src={Next} alt="Next" />
                                    </button>


                                </Col>
                                <Col sm={12} md={12}>
                                    {successMsg || errorMsg ? (
                                        <div className='alert-toast'>
                                            <p className={successMsg ? "success-message" : "form-error"}>{successMsg}</p>
                                            <button className="close-img" onClick={() => setSuccessMsg('')}>X</button>
                                        </div>
                                    ) : null}
                                </Col>

                            </Row>
                        </form>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
